import React from 'react';
import { Dialog, DialogActions, DialogContent, MenuList } from '@mui/material';
import { Button } from '../main';
import { TourItem } from '../tour';

/** Dialog box to change tours a place is on. */
export default function UpdateToursDialog({place, tours, onTourUpdate, close,
  open}) {
  const { id, name } = place || {};
  return(
    <Dialog open={open}>
      <DialogContent>
        Tournées pour {name}<br/>
        <MenuList sx={{mt: 5}}>
          {tours
            .sort((a,b) => a.name.localeCompare(b.name))
            .map(t => 
              <TourItem key={t.id} placeId={id} setTour={onTourUpdate} {...t} />
            )}
        </MenuList>
      </DialogContent>
      <DialogActions>
        <Button secondary mt={0} onClick={close}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>);
};
