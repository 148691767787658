import React from 'react';
import { Select } from '@mui/material';
import TourItem from './TourItem';


/** Component to add or remove a place from tours. */
export default function ToursSelect ({placeId, tours}) {
  return(
    <Select 
      variant="standard"
      fullWidth
      multiple
      displayEmpty
      value={tours.filter(t => t.places.some(p => p.id === placeId))}
      renderValue={(selected) => 
        selected.map(({name})=>name).join(', ') || 'Aucune tournée'
      }
    >
      {tours?.map(tour =>
        <TourItem key={tour.id} {...{placeId, ...tour}}/>)
      }
    </Select>
  );
};
