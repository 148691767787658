import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { useUserIs } from '@clients/shared/src/user/services';
import { useWorkplace } from '@clients/shared/src/place/services';
import { FormPage, Button, H6, IconButton } from '../main';
import BacCard from '../event/BacCard';
import { Load } from '../_utils';
import BacHistory from './BacHistory';
import BacUpdateForm from './BacUpdateForm';
import BacShowIdForm from './BacShowIdForm';

/** The page which handles individual bac. */
export default function Bac ({match}) {
  const { bac_id: shownId } = match.params;
  const [ bac, setBac ] = useState({});
  const [ isEditing, setIsEditing ] = useState(false);
  const userIs = useUserIs()
  const api = useApi();
  const workplace = useWorkplace();
  const isEditionAllowed = 
    userIs('superadmin') || userIs('leaser') || bac.owner_id===workplace.id;
  const isButtonsAllowed = isEditionAllowed || bac.caretaker_id===workplace.id;
  const refreshBac = () => api.get('/bacs/' + shownId).then(setBac);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {refreshBac()}, [shownId]);
  const onClickButton = description => {
    api.post('/events', {bac_shown_id: bac.shown_id, description})
    .then(refreshBac);
  }
  return ( 
    <FormPage
      title={`Bac ${shownId}`}
      options={isEditionAllowed && bac.id &&
        <IconButton type='settings' onClick={()=>{setIsEditing(e => !e)}}/>
      }
    >
      <Load isLoading={api.isLoading}>
        {!bac.id
        ? <H6>{`Le numéro de bac ${shownId} n'existe pas ou plus.`}</H6>
        : <>
          <Grid container direction='column' alignItems='center'>
            <BacCard bacId={bac.shown_id}/>
            <BacUpdateForm {...{bac, isEditing, refreshBac}} />
            {isEditing && <BacShowIdForm shown_id={bac.shown_id} />}
            {!isEditing &&
            <Box mt={6} sx={{display:'flex', gap: 6}}>
              <Button
                onClick={() => onClickButton('lost')}
                disabled={api.isLoading || !isButtonsAllowed}
              >
                Bac perdu
              </Button>
              <Button
                onClick={() => onClickButton('broken')}
                disabled={api.isLoading || !isButtonsAllowed}
              >
                Bac cassé
              </Button>
            </Box>}
          </Grid>
          <BacHistory events={bac.events} shownId={bac.shown_id}/>
        </>
        }
      </Load>
    </FormPage>
  );
};
