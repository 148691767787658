import {
  LOAD_EVENTS, ADD_EVENT, DELETE_EVENT, UPDATE_EVENT, REPLACE_EVENT, 
  SET_EVENTS_SYNC_REQUIRED, SET_EVENTS_IS_SYNCING
  } from "../actionTypes";

const initialState = {
  allIds: [],
  byIds: {},
  loadDate: (new Date()).toISOString(),
  syncRequired: false,
  isSyncing: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_EVENTS: {
      const {events, loadDate} = action.payload;
      return {
        ...state,
        loadDate,
        allIds: events.map(event => event.id),
        byIds: events.reduce( 
        (obj, event) => {
            obj[event.id] = event
            return obj
          }, {})
      };
    }
    case ADD_EVENT: {
      const {id, event} = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: event
        }
      };
    }
    case DELETE_EVENT: {
      const {id} = action.payload;
      const { [id]:event, ...othersByIds } = state.byIds;
      return {
        ...state,
        byIds: othersByIds,
        allIds: state.allIds.filter(i => i!==id),
      };
    }
    case REPLACE_EVENT: {
      const {prevId, newEvent} = action.payload;
      const { [prevId]:prevEvent, ...othersByIds } = state.byIds;
      return {
        ...state,
        allIds: [...state.allIds.filter(i => i!==prevId), newEvent.id],
        byIds: { 
          ...othersByIds,
          [newEvent.id]: newEvent
        }
      };
    }
    case UPDATE_EVENT: {
      const {id, key, value} = action.payload;
      return {
        ...state,
        byIds: { 
          ...state.byIds,
          [id]: {
            ...state.byIds[id],
            [key]: value,
          }
        }
      };
    }
    case SET_EVENTS_SYNC_REQUIRED: {
      const {syncRequired} = action.payload;
      return {
        ...state,
        syncRequired
      };
    }
    case SET_EVENTS_IS_SYNCING: {
      const {isSyncing} = action.payload;
      return {
        ...state,
        isSyncing
      };
    }
    default:
      return state;
  }
};
