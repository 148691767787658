import React from 'react';
import { Dialog, DialogActions, DialogContent, Box } from '@mui/material';
import { Button } from '../main';

/** Confirmation dialog box when deleting a place. */
export default function DeletePlaceDialog({place, onConfirm, onCancel, open}) {
  const { name, address, city, postal_code } = place || {};
  return(
    <Dialog open={open}>
      <DialogContent>
        Confirmez-vous la suppression du point de livraison <br/>
        <Box p={0} m={4} color='text.primary' fontSize={13}>
          <Box color='text.secondary' fontSize={16}>{name}</Box>
          {address}<br/>{city} - {postal_code}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button secondary mt={0} onClick={onConfirm}>
          Confirmer
        </Button>
        <Button secondary mt={0} onClick={onCancel}>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>);
};
