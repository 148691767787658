import React, { useEffect, useState } from 'react';
import { useApi } from '@clients/shared/src/utils';
import { StickerGrid } from './QRSticker';
import './qr.css';


/** Page to create QR code stickers. */
const PlaceQR = ({ pageName, pagePath, startAt, size, col }) => {
  const api = useApi();
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    api.get('/places')
      .then(places => places.map(place => ({
          code: place.id, name: place.name
      })))
      .then(setPlaces);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const placesToRender = startAt ? places.slice(startAt - 1, size) : [];

  return(
    <StickerGrid 
      col={col} 
      stickers={placesToRender}
      page={{name: pageName, path: pagePath}}
    />
  );
}

export default PlaceQR;
