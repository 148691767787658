import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { usePlacesOrState, useWorkplace
  } from '@clients/shared/src/place/services';
import { useApi, makeUrl  } from '@clients/shared/src/utils';
import { useUserIs } from '@clients/shared/src/user/services';
import { Page, IconButton} from '../main';
import { SearchBar, useSearch } from '../searchbar';
import DeletePlaceDialog from './DeletePlaceDialog';
import UpdateToursDialog from './UpdateToursDialog';


/** Page to edit destinations for a workplace. */
export default function PlacesEdit() {
  const api = useApi();
  const isAdmin = useUserIs()('admin');
  const [ placeToDelete, setPlaceToDelete ] = useState(null);
  const [ placeToUpdateTours, setPlaceToUpdateTours ] = useState(null);
  const [ tours, setTours ] = useState([]);
  const { id: workplace_id } = useWorkplace();
  const { places: allPlaces, updatePlace, deletePlace } = 
    usePlacesOrState(workplace_id, isAdmin ? {include: 'employees'} : {});
  useEffect(() => {
    api.get(makeUrl('/tours', {workplace_id})).then(setTours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id]);
  const addProperties = place => {
    place.onTours = tours.filter(t => t.places.some(p => p.id === place.id))
    place.onDelete = () => setPlaceToDelete(place);
    return place
  }
  const filters = [
    {template: 'placesByText'},
    {template: 'workplace'},
    {template: 'tours', items: tours}
  ]
  const [ searchBarProps, places ] = useSearch(filters, allPlaces);
  useEffect(() => {
      searchBarProps.setSearchValues(v => ({...v, tourId: ''}));
  }, [workplace_id]);
  const deleteDestination = () => {
    api.delete(makeUrl('/destinations/'+placeToDelete.id, {workplace_id}))
      .then(() => deletePlace(placeToDelete))
      .then(() => setPlaceToDelete(null));
  };
  const processRowUpdate = (place, prevPlace) => {
    const {placeRefId, ref} = place;
    return (() => {
      if (ref === prevPlace.ref){
        return api.put(makeUrl(`/places/${place.id}`, {workplace_id}), place)
      } else if (placeRefId) {
        return api.put(`/placerefs/${placeRefId}`, {ref})
      } else {
        return api.post('/placerefs', {ref, target_id: place.id, workplace_id})
      }
    })()
      .then(() => updatePlace(place))
      .then(() => place)
      .catch(() => prevPlace)
  }

  const columns = [
    { field: 'name',
      headerName: 'Lieu',
      flex: 1,
      editable: true,
      preProcessEditCellProps:
        ({props})=>({...props, error: !props.value.length})
    },
    { field: 'address', headerName: 'Adresse', flex: 1, editable: true },
    { field: 'city', headerName: 'Ville', flex: 1, editable: true },
    { field: 'postal_code', headerName: 'CP', editable: true },
    { field: 'onTours',
      headerName: 'Tournée(s)',
      flex: 1,
      editable: false,
      valueFormatter: value => value?.map(({name}) => name).join(',\n'),
      onCellClick: place => isAdmin && setPlaceToUpdateTours(place),
    },
    { field: 'ref', headerName: 'Ref', flex: 1, editable: true },
    { field: 'emails',
      headerName: 'Email(s)',
      flex: 1,
      editable: false,
      valueFormatter: (value, row) =>
        row.employees?.map(({email}) => email).join(',\n'),
      hide: !isAdmin,
    },
    { field: 'options',
      type: 'actions',
      headerName: 'Options',
      getActions: ({row: place}) => [
        <IconButton type='edit' 
          to={makeUrl(`/places/${place.id}/edit`, {workplace_id})}/>,
        <IconButton type='delete' text='Supprimer' onClick={place.onDelete}/>,
      ]
    },
  ].filter(({hide}) => !hide);

  return (
    <Page 
      options={
        <IconButton type='add' to={makeUrl('/places/create', {workplace_id})}/>
      }
    >
      <DeletePlaceDialog 
        place={placeToDelete}
        onConfirm={deleteDestination}
        onCancel={() => setPlaceToDelete(null)}
        open={placeToDelete !== null}
      />
      <UpdateToursDialog 
        place={placeToUpdateTours}
        tours={tours}
        onTourUpdate={updatedTour => setTours(tours => 
          [...tours.filter(t => t.id !== updatedTour.id), updatedTour])}
        close={() => setPlaceToUpdateTours(null)}
        open={placeToUpdateTours !== null}
      />
      <SearchBar {...searchBarProps}/>
      <Box>
        <DataGrid 
          rows={places.map(addProperties)} 
          columns={columns}
          onCellClick={({colDef, row}) => colDef.onCellClick?.(row)}
          processRowUpdate={processRowUpdate}
          loading={api.isLoading}
        />
      </Box>
    </Page>
  );
};
