import React, { useEffect, useState } from 'react';
import { Paper, Box, TableContainer, Table, TableHead, TableBody, TableCell,
  TableRow} from '@mui/material';
import { useApi, pluralize } from '@clients/shared/src/utils';
import { usePlace } from '@clients/shared/src/place/services';
import { Page, Tooltip } from '../main';
import { Load }  from '../_utils';


/** The list of bacs' situation by model. */
export default function PlaceBacLeasedAnalysis({ match }) {
  const [bacsAnalysis, setBacsAnalysis] = useState([]);
  const [leases, setLeases] = useState([]);
  const { id, name } = usePlace(match.params?.place_id) || {}
  const api = useApi();
  useEffect(() => {if(id){
    api.get().then(setBacsAnalysis);
    api.get(`/places/${id}/leases?active=True`).then(setLeases);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }}, [id]);
  const combinedData = groupDataByModel(bacsAnalysis, leases)
  
  return (
    <Page title={`Situation des bacs loués à ${name}`} >
    <Load isLoading={api.isLoading}>
      <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
        <TableContainer>
          <Table> 
            <colgroup>
              <col width='33%'/>
              <col width='15%'/>
              <col width='13%'/>
              <col width='13%'/>
              <col width='13%'/>
            </colgroup>
            <TableHead> 
              <TableRow>
                <TableCell> Modèle </TableCell>
                <TableCell> Total loués </TableCell>
                <TableCell> 
                  <Tooltip text='Bacs en stock chez client' value='Stock'/>
                </TableCell>
                <TableCell>
                  <Tooltip text='Bacs marqués comme perdus' value='Perdus'/>
                </TableCell>
                <TableCell>
                  <Tooltip text='Bacs livrés par le client' value='Livrés'/>
                </TableCell>
                <TableCell>
                  <Tooltip 
                    text='Bacs loués - ceux en stock - ceux livrés' 
                    value='En lavage'
                  />
                </TableCell>
              </TableRow>
            </TableHead> 
            <TableBody>
            {combinedData.map(
              ({model_id, model, stock, lost, used, leased}) =>
              <TableRow key={model_id}>
                <TableCell>{model}</TableCell>
                <TableCell>{pluralize('bac', leased)}</TableCell>
                <TableCell>{stock}</TableCell>
                <TableCell>{lost}</TableCell>
                <TableCell>{used}</TableCell>
                <TableCell>{leased - stock - used}</TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {(bacsAnalysis.length===0) && <h3>Aucun modèle trouvé</h3>}
      </Box>
    </Load>
    </Page>
  );
};

const groupDataByModel = (analysis, leases) => {
  const combinedData = analysis.map(modelA => ({...modelA, leased: 0}));
  const emptyA = {stock: 0, lost: 0, used: 0};
  leases.forEach(({model_id, model, bac_count}) => {
    const index = combinedData.findIndex(item => item.model_id === model_id);
    index >= 0
      ? combinedData[index].leased += bac_count
      : combinedData.push({...emptyA, model_id, model, leased: bac_count})
  });
  return combinedData.sort(
      (a, b) => a.model.localeCompare(b.model, 'fr', {sensitivity: "base"}));
}
