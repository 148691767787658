import { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentUser } from '@clients/shared/src/user/services';
import { useKeyboardListener } from '@clients/shared/src/scan';

/** A component which listen to scan events. */
export default function KeyboardListenerWeb () {
  const { workplace_id } = useCurrentUser();
  const history = useHistory();
  const location = useLocation();
  const destinationId = (() => {
    if ('/returns' === location.pathname) {
      return workplace_id
    } else if ((/^\/places\/[0-9]+$/).test(location.pathname)) {
      return +location.pathname.match(/^\/places\/([0-9]+)$/)[1];
    }
  })();
  const redirection =  (pathname) => {
    console.log('pushing', pathname);
    history.push(pathname)
  }
  const {addChar, parseText}=useKeyboardListener(redirection,destinationId,true)
  const handleKeydown = event => {
    const { key, target, getModifierState } = event;
    // Prevent Mozilla Firefox Quick Search from opening while scanning url
    if (key === '/' && target.nodeName !== 'INPUT') {
      event.preventDefault();
    }
    if (target.nodeName==='INPUT') {
      return;
    }
    if (['Shift', 'Enter', ';'].indexOf(key) < 0) {
      const capslock = getModifierState && event.getModifierState('CapsLock');
      addChar(capslock ? revertsCapsLock(key) : key);
    } else if (key === 'Enter' || key === ';') {
      parseText();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationId]);

  return null;
};

/** Reverts CapsLock effect during URL scan. */
const revertsCapsLock = char => {
  if (capsLockModifierSpecialCharMap[char] !== undefined) {
    return capsLockModifierSpecialCharMap[char];
  } else if (char.toLowerCase() === char) {
    return char.toUpperCase();
  } else {
    return char.toLowerCase()
  }
};

const capsLockModifierSpecialCharMap = {
  '&':'1',
  'é':'2',
  '"':'3',
  '\'':'4',
  '(':'5',
  '-':'6',
  'è':'7',
  '_':'8',
  'ç':'9',
  'à':'0',
  ';':'.',
  ':':'/',
  '1':'&',
  '2':'é',
  '3':'"',
  '4':'\'',
  '5':'(',
  '6':'-',
  '7':'è',
  '8':'_',
  '9':'ç',
  '0':'à',
  '.':';',
  '/':':',
};
