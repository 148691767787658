import { useSelector, useDispatch, } from 'react-redux';
import { getErrorMsg, } from '../redux/selectors';
import { setErrorMsg, } from '../redux/actions';

/** Get error message from store hook. */
export function useErrorMsg () {
  return useSelector(state => getErrorMsg(state));
};


/** Set error message in store hook. */
export function useSetErrorMsg () {
  const dispatch = useDispatch();
  return msg => dispatch(setErrorMsg(msg));
};
