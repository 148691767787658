import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Box, CircularProgress } from "@mui/material";
import { useApi, makeUrl } from "@clients/shared/src/utils";
import { Button, Sub2 } from './';


/** Button to declare events' bacs as lost according filters in search-query  */
export default function BulkLostButton ({callback}){
  const location = useLocation();
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => setShowSuccess(false), [location]) 
  const [message, setMessage] = useState('');
  useEffect(() => setMessage(''), [location])
  const api = useApi();
  const onClick = () => {
    api.post(makeUrl('/bulk/lost', location.search))
    .then(message => setMessage(message))
    .then(() => setShowSuccess(true))
    .then(() => callback && callback());
  }
  return(
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center',
      mb: 4}}
    >
      <>
        <Button secondary
          children='Déclarer comme perdus'
          {...{showSuccess, onClick}}
        />
        {api.isLoading && 
        <CircularProgress style={{color: '#26c98c', marginLeft: 4}} size={25}/>
        }
      </>
      {message && <Sub2>{message}</Sub2>}
    </Box>
  )
}
