import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import { useApi } from '@clients/shared/src/utils';
import { Card, DatePicker, FormPage, SubmitButton } from '../main';
import PlaceAutocomplete from '../place/PlaceAutocomplete';


/** The form to create or edit a specific pickup. */
export default function Pickup({match, history, location}) {
  const api = useApi();
  const [ pickup, setPickup ] = useState({
    "bac_count": 0,
    "pallet_count": '',
    "bac_owner_id": '',
    "carrier_id": null,
    "destination_id": '',
    "place_id": '',
    "planed_delivery": null,
    "planed_loading": null,
    ...location.state,
    "id": match.params.pickup_id,
    "deleted": false,
  });
  const [ submitSucceeded, setSucceeded ] = useState(false);
  useEffect(() => {
    pickup.id && api.get().then(setPickup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup.id]);
  const submitNew = values => {
    api.post('/pickups', values)
    .then(() => history.replace('/pickups'))
  }
  const submitUpdate = values => {
    api.put(null, values)
    .then(setPickup)
    .then(() => setSucceeded(true))};
  const submit = values => 
    (pickup.id ? submitUpdate : submitNew)(cleanFormValues(values));

  return (
    <FormPage title={(pickup.id ?'Modification':'Création')+' d\'une collecte'}>
      <Formik
        onSubmit={submit}
        initialValues={pickup}
        enableReinitialize
      >
        {({touched, errors}) => {
          return(
            <Card title={pickup.id ? 'Modification' : 'Nouvelle collecte'}>
              <Form>
                <PlaceAutocomplete
                  name='place_id'
                  label='Lieu de collecte'
                  required
                  error={touched['place_id'] && !!errors['place_id']}
                  helperText={errors['place_id']}
                />
                <DatePicker
                  name='planed_loading'
                  label='Collecte prévu le'
                  required
                  error={touched['planed_loading']&& !!errors['planed_loading']}
                  fullWidth
                />
                <PlaceAutocomplete
                  name='destination_id'
                  label='Lieu de livraison'
                  required
                  error={touched['destination_id']&& !!errors['destination_id']}
                  helperText={errors['destination_id']}
                />
                <DatePicker
                  name='planed_delivery'
                  label='Livraison prévu le'
                  clearValue={null}
                  fullWidth
                />
                <PlaceAutocomplete
                  name='carrier_id'
                  label='Transporteur'
                  error={touched['carrier_id'] && !!errors['carrier_id']}
                  helperText={errors['carrier_id']}
                />
                <PlaceAutocomplete
                  name='bac_owner_id'
                  label='Propriétaire des bacs'
                  required
                  error={touched['bac_owner_id'] && !!errors['bac_owner_id']}
                  helperText={errors['bac_owner_id']}
                />
                <Field
                  component={TextField}
                  name='bac_count'
                  label='Nombre de bacs (approximatif)'
                  variant="standard"
                  type='number'
                  inputProps={{ min: 1 }}
                  required
                  fullWidth
                  margin='dense'
                />
                <Field
                  component={TextField}
                  name='pallet_count'
                  label='Nombre de palettes'
                  variant="standard"
                  type='number'
                  inputProps={{ min: 0 }}
                  fullWidth
                  margin='dense'
                />
                <Box>
                  <Field
                    component={Checkbox}
                    type='checkbox'
                    name='deleted'
                  />
                  <label>Effacé</label>
                </Box>
                <SubmitButton {...{submitSucceeded}}/>
              </Form>
            </Card>
          )
        }}
      </Formik>
    </FormPage>
  );
};

const cleanFormValues = ({carrier_id, pallet_count, ...otherValues}) => ({
  ...otherValues,
  carrier_id: carrier_id === '' ? null : carrier_id,
  pallet_count: pallet_count === '' ? null : pallet_count
})
