import React from 'react';
import { useFormikContext } from 'formik';
import { red, orange, yellow, blue, green } from '@mui/material/colors';
import { LinearProgress } from '@mui/material';


const hasRegExp = (value, regExp, weight) =>
  (new RegExp(regExp).test(value)) ? weight : 0;
const hasNumber = value =>  hasRegExp(value, /[0-9]/, 10);
const hasLowerChar = value =>  hasRegExp(value, /[a-z]/, 26);
const hasUpperChar = value =>  hasRegExp(value, /[A-Z]/, 26);
const hasSpecial = value =>  hasRegExp(value, /[!#@$%^&*)(+=._-]/, 17);
const calcAlphabetSize = word =>
  hasNumber(word) + hasLowerChar(word) + hasUpperChar(word) + hasSpecial(word);

/** Calc password's entropy based on this alphabet size.
 * Inspired from https://www.bee-man.us/computer/password_strength.html */
const passowrdBitsEntropy = data => {
  const password = (data === undefined) ? '' : String(data)
  const alphabetSize = calcAlphabetSize(password);
  return Math.round(Math.log2(Math.pow(alphabetSize, password.length)));
};

/** Calc password strengh score between 0 and 4.*/
const passwordStrengthScore = password => {
  const bits = passowrdBitsEntropy(password);
  return [40, 60, 80, 100, Number.POSITIVE_INFINITY].findIndex(x => bits < x);
}

const strengths = {
  0: {progress: 20, color:red},
  1: {progress: 40, color:orange},
  2: {progress: 60, color:yellow}, 
  3: {progress: 80, color:blue},
  4: {progress: 100, color:green},
};

/** Colored meter based on password strengh score. */
export default function PasswordMeter({name}) {
  const { values } = useFormikContext();
  const password = values[name]
  const score = passwordStrengthScore(password);
  const { progress, color } = strengths[score];
  
  return (
    <LinearProgress
        value={progress}
        variant='determinate'
        sx={{
          height: 15,
          backgroundColor: 'transparent',
          '& .MuiLinearProgress-bar': { backgroundColor: color[600] }
        }}
      />
  );
}
