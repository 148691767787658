import React from 'react';
import { useFormikContext } from 'formik';
import Button from './Button';

/** Button used to update a form. */
export default function SubmitButton({submitSucceeded, ...others}) {
  const { dirty, isSubmitting, values } = useFormikContext();
  return (
    <Button
      type='submit'
      disabled={!dirty || isSubmitting}
      showSuccess={submitSucceeded && !dirty}
      fullWidth
      children={values.id ? 'Mettre à jour' : 'Créer'}
      {...others}
    />);
};
