import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import qs from 'query-string';
import { useApi, isEmail, validatePhone, makeUrl
  } from '@clients/shared/src/utils';
import { usePlaceOrState, useWorkplace
  } from '@clients/shared/src/place/services';
import { FormPage, SubmitButton, Card } from '../main';
import PasswordMeter from './PasswordMeter';

/** The new user creation form. */
export default function CreateUser ({history, match, location}) {
  const api = useApi();
  const [place, ] = usePlaceOrState(match.params.place_id);
  const search = qs.parse(location.search);
  const {id: workplace_id} = useWorkplace();
  const user = {
    ...fields.reduce((user, field) => ({...user, [field.name]: ''}), {}),
    ...search,
  };
  const submit = values => api.post(`/places/${place.id}/users`, values)
    .then(() => 
      history.push(makeUrl('/users', {place_id: place.id, workplace_id})));

  return (
    <FormPage title='Créer un utilisateur' subtitle={place.name || ''}>
      <Card title='Profil'>
        <Formik onSubmit={submit} initialValues={user}>
          <Form>
            {fields.map(({name, label, type, validate}) => (
            <Field
              component={TextField}
              key={name}
              name={name}
              label={label}
              margin='dense'
              variant="standard"
              fullWidth
              {...(type ? {type} : {})}
              {...(validate ? {validate} : {})}
              {...(name==='email' ? {required: true} : {})}
              //required //Disabled to add restaurants emails
            />))}
            <PasswordMeter name='password'/>
            <SubmitButton/>
          </Form>
        </Formik>
      </Card>
    </FormPage>);
};

export const fields = [
  {name: 'first', label: 'Prénom'},
  {name: 'last', label: 'Nom'},
  {name: 'email', label: 'Email', type: 'email', validate: isEmail},
  {name: 'phone', label: 'Portable', type: 'tel', validate: validatePhone},
  {name: 'password', label: 'Mot de passe', type: 'password'},
];
