import React from 'react';
import { useHistory } from 'react-router-dom';
import { Snackbar, } from '@mui/material';
import { Alert } from '@mui/material';
import { useErrorMsg, useSetErrorMsg } from '@clients/shared/src/utils';
import { Sub2 } from  './Typography';

/** Error banner notification. */
export default function ErrorBanner() {
  const message  = useErrorMsg();
  const setMsg = useSetErrorMsg();
  const history = useHistory();
  const close = () => setMsg();
  history.listen(close);
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={message ? true : false}
    >
      <Alert 
        severity='error' 
        onClose={close} 
        style={{backgroundColor: '#f8d7da', padding: '16px',}}
      >
        <Sub2 color='error'>{message}</Sub2>
      </Alert>
    </Snackbar>);
};
