import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import qs from 'query-string';
import { Table, TableHead, TableRow, TableCell, TableBody, 
  Paper, Checkbox, Grid, Box } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import useTourState from '@clients/shared/src/tour/tourState';
import { useAddTour } from '@clients/shared/src/tour/services';
import { FormPage, Link, SubmitButton, Card, Button } from '../main';
import TourPlaceCheckbox from './TourPlaceCheckbox';


/** Page to edit a tour. */
export default function Tour({location, match, history}) {
  const api = useApi();
  const { tour_id } = match.params;
  const { workplace_id } = qs.parse(location.search, {parseNumbers: true});
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const { tour, setTour, drivers, places } = useTourState(tour_id);
  const addTour = useAddTour();

  const handleChange = (url, key, event) => (event.target.checked
    ? api.post(match.url + url, {[key] : event.target.value})
    : api.delete(match.url + url + '/' + event.target.value))
    .then(setTour);

  const submit = values => {
    if (values.id) {
      return api.put('/tours/' + values.id, values).then(setTour)
        .then(() => setSucceeded(true), e=>({e}));
    } else {
      api.post('/tours'+(workplace_id?'?workplace_id='+workplace_id:''),values)
        .then(tour => {
          !workplace_id && addTour(tour);
          history.replace('/tours/' + tour.id);});
    }
  };

  return (
    <FormPage title={tour.id ? tour.name : 'Nouvelle tournée'}>
      <Card mt={6} title='Tournée'> 
        <Formik 
          initialValues={{name:'', ...tour}} 
          onSubmit={submit} 
          enableReinitialize
        >
          <Form>
            <Field
              component={TextField}
              name={'name'}
              label={'Nom'}
              required
              variant="standard"
              fullWidth
            />
            <SubmitButton {...{submitSucceeded}}/>
            <Button
              fullWidth
              disabled={tour.deleted || tour.name==='destinations_tour'}
              onClick={
                () => api.put('/tours/' + tour.id, {...tour, 'deleted': true})
                  .then(history.goBack)
              }
            >
              Supprimer
            </Button>
          </Form>
        </Formik>
      </Card>
      {tour.id &&
      <Grid item container
        direction='row'
        justifyContent='space-evenly'
        alignItems='flex-start'
      >
        <Paper component={Box} m={{xs:2, md:4}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lieux</TableCell>
                <TableCell>Appartenance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {places.sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => 
              <TableRow hover key={id}>
                <TableCell>
                  <Link to={'/places/' + id}>{name}</Link>
                </TableCell>
                <TableCell>
                  <TourPlaceCheckbox {...{tour, setTour, placeId: id}} />
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </Paper>
        <Paper component={Box} m={{xs:2, md:4}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Chauffeurs</TableCell>
                  <TableCell>Appartenance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers.sort((a, b) => a.name.localeCompare(b.name))
              .map(({id, name}) => 
              <TableRow key={id}>
                <TableCell>
                  <Link to={'/users/' + id}>{name}</Link>
                </TableCell>
                <TableCell>
                  <Checkbox 
                    value={id} 
                    checked={tour.drivers.some((driver) => driver.id === id)}
                    onChange={e => handleChange('/drivers', 'driver_id', e)}
                  />
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </Paper>
      </Grid>}
    </FormPage>);
};
