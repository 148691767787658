import React, { useState, useEffect } from 'react'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { useUpdateUser, useCurrentUser,
  } from '@clients/shared/src/user/services';
import { Card, Tooltip } from '../main';


/** The form to add or remove role to an user. */
export default function Roles ({initialRoles, userId}) {
  const apiUser = useApi();
  const apiRoles = useApi();
  const [ roles, setRoles ] = useState([]);
  const [ userRoles, setUserRoles ] = useState(initialRoles);
  const updateUser = useUpdateUser();
  const currentUser = useCurrentUser();
  useEffect(() => {setUserRoles(initialRoles);}, [initialRoles]);
  useEffect(() => {
    apiRoles.get('/roles').then(setRoles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRole = (name, isChecked) => (
    setUserRoles(userRoles => {
      if (isChecked && (userRoles.indexOf(name)<0)) {
        return [...userRoles, name]
      } else if (!isChecked) {
        return userRoles.filter(role => role!==name)
      } else {
        return userRoles
      }}));

  const handleChange = (event) => {
    const { name, checked } = event.target;
    changeRole(name, checked);
    const url = `/users/${userId}/roles`;
    (checked ? apiUser.post(url,{name}) : apiUser.delete(url+'/'+name))
      .then(() => {(currentUser.user_id===userId) && updateUser();})
      .catch(error => changeRole(name, !checked))
  };

  return(
    <Card mt={2} title='Groupes'>
      <FormGroup
        row
        sx={{
          maxWidth: 400,
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        {roles.map(({name, label, description}) =>
          <FormControlLabel
            key={name} 
            label={<Tooltip text={description} value={label || name}/>}
            sx={{margin: 0}}
            control={ 
              <Checkbox 
                checked={userRoles.indexOf(name) >= 0}
                name={name}
                onChange={handleChange}
              /> 
            }
          />
        )}
      </FormGroup>
    </Card>
  )
};
