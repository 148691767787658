import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Grid } from '@mui/material';
import { useApi, isEmail, validatePhone} from '@clients/shared/src/utils';
import { useUserIs } from '@clients/shared/src/user/services';
import { FormPage, Button, SubmitButton, Link, Card } from '../main';
import Roles from './Roles';
import Workplace from './Workplace';
import MessagesSettings from './MessagesSettings';


/** The form to edit a specific user. */
export default function User ({location}) {
  const [ user, setUser ] = useState({first:'', last:'', email:'', phone:''});
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const { get, put, isLoading } = useApi();
  const userIs = useUserIs();
  const workplace = user.workplace ? 
    { id: user.workplace_id, name: user.workplace } : undefined;
  useEffect(() => {get().then(setUser)}, [get]);
  const submit = values => put(undefined, values) 
    .then(() => {setUser(values); setSucceeded(true)})
    .catch(e => {setSucceeded(false); return {e}});
  const name = [user.first, user.last].filter(x => x).join(" ") || user.email;
  return (
    <FormPage title={name}>
      <Card title="Modifier les informations de l'utilisateur">
        <Formik onSubmit={submit} initialValues={user} enableReinitialize>
          <Grid container component={Form} direction='column'>
          {fields.map(({name, label, required, validate}) => (
            <Field
              key={name}
              name={name}
              label={label} 
              {...{validate, required}}
              component={TextField}
              margin='dense'
              variant="standard"
            />))}
            <SubmitButton {...{submitSucceeded}}/>
            <Button secondary component={Link} fullWidth
              to={{
                pathname: '/users/'+user.user_id+'/password', 
                state: {fullName: user.first + ' ' + user.last} 
              }}
              >
              Changer le mot de passe
            </Button>
          </Grid>
        </Formik>
      </Card>
      {!isLoading && userIs('superadmin') && 
        <Workplace initialWorkplace={workplace} {...{user}} />}
      {userIs('admin') &&
        <Roles initialRoles={user.roles || []} userId={user.user_id} />}
      {userIs('superadmin') && 
        <MessagesSettings 
          messagesSettings={user.messages_settings || []}
          user={user}
        />}
    </FormPage>);
};

const fields = [
  {name: 'first', label: 'Prénom', required: true},
  {name: 'last', label: 'Nom', required: true},
  {name: 'email', label: 'Email', required: true, validate: isEmail},
  {name: 'phone', label: 'Portable', validate: validatePhone},
]