import { combineReducers } from 'redux';
import currentUser from './current-user';
import events from './events';
import places from './places';
import tours from './tours';
import errors from './errors';
import scanParams from './scan-params';

export default combineReducers({currentUser, events, places, tours, 
    errors, scanParams});

