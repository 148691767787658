import React from 'react';
import { Grid } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useCurrentUser } from '@clients/shared/src/user/services';
import Link from './Link';
import Avatar from './Avatar';
import { H4 } from './Typography';


/** App header. */
export default function Header({toggleMenu}) {
  const branchName = process.env.REACT_APP_COMMIT_REF_NAME || '';
  const currentUser = useCurrentUser();
  return (
    <Grid item container 
      component='header' 
      alignItems='center' 
      justifyContent='space-between'
      sx={sx}
    >
      <Grid item container alignItems='center' xs={10}>
        <Grid item 
          component={MenuIcon} 
          onClick={toggleMenu}
          sx={{
            marginRight: 2,
            cursor: 'pointer',
            display: {'lg': 'none'}
          }}
        />
        <Grid item>
          <H4 sx={{fontWeight: 600}}>
            {(branchName==='master')
              ? 'Pandobac Scanner' : `${branchName.slice(0, 40)}`
            }
          </H4>
        </Grid>
      </Grid>
      <Grid item sx={{flexGrow: 0}}>
        {currentUser.user_id &&
        <Link to={'/users/profile'}> 
          <Avatar user={currentUser}/>
        </Link>}
      </Grid>
    </Grid>
  ); 
}


const sx = theme => ({
  position: 'sticky',
  width: '100%',
  zIndex: 1,
  top: 0,
  py: 0,
  px: 5,
  backgroundColor: 'secondary.main',
  color: 'white',
  fontWeight: 'bold',
  height: {
    xs: theme.components.header.defaultProps.height.xs,
    md: theme.components.header.defaultProps.height.default,
  }
})
