import React from 'react';
import { Avatar as MuiAvatar, Box } from '@mui/material';

/** User avatar. */
export default function Avatar({user={}, color='avatar.color'}) {
  const { first, last } = user;
  return (
    <Box 
      component={MuiAvatar} 
      color={color}
      bgcolor='avatar.bg'
      width={{xs: 28, md:40}}
      height={{xs: 28, md:40}}
      fontSize={{xs: 15, md:20}}
      fontWeight={600}
      border={1.5}
    >
      {(first ? first[0] : '') + (last ? last[0] : '')}
    </Box>); 
};
