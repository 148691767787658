import React, { useEffect } from 'react';
import { useApi } from '@clients/shared/src/utils';
import { useSaveUser } from '@clients/shared/src/user/services';
import Page from '../main/FormPage';

/** The page to handle recovered password link. */
export default function Recover ({match, history}) {
  const api = useApi();
  const saveUser = useSaveUser();
  const { token } = match.params;
  useEffect(() => {
    api.post('/users/recover', {token})
      .then(user => {
        saveUser(user);
        history.push('/users/' + user.user_id + '/password');
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Page title='Verififcation du lien'></Page>;
};

