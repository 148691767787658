import React  from 'react';
import { Box } from '@mui/material';
import { eventServices } from '@clients/shared/src/event/event-services';
import { stringifyDateTime } from '@clients/shared/src/utils';
import { Card } from '../main';

/** Show to history of a bac. */
export default function BacHistory ({events, shownId}) {
  return (
    <Box 
      sx={{
        mt: 6, mx: 'auto', px: 1,
        display: 'flex', justifyContent: 'center'
      }}
    >
      <Card title={`Historique du bac ${shownId}`}>
        {events?.length===0 && "Pas d'historique pour ce bac."}
        <Box component='ul' m={0} mt={1} p={0} sx={{listStyleType:'none'}} >
          {eventServices.sort(events).map(({
            id, created, destination, description, is_passage, deleted, author,
            is_clean
          }) =>
          <li key={id}>
            {stringifyDateTime(new Date(created))}&nbsp;
            → {destination}&nbsp;
            {(is_clean !== null) && `${is_clean ? 'propre' : 'sale'} `}
            {description && <i>[{description}]&nbsp;</i>}
            {is_passage && <i>[scan de passage]&nbsp;</i>}
            {deleted && <i>[supprimé]&nbsp;</i>}
            ({author.first} - {author.workplace})
          </li>)}
        </Box>
      </Card>
    </Box>);
};
