import React, {forwardRef} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

/** A link which adds styles to react-router-dom Link component. */
export default forwardRef((props, ref) => {
  //We need to use forwardRef to use Link as a prop in MaterialUI cpts.
  return ( 
    <MuiLink
      underline='none'
      color='inherit'
      component={RouterLink}
      {...props}
    />);
});
