import React from 'react';
import { CircularProgress } from '@mui/material';

/** Display a loading circle if children components are loading. */
const Load = ({isLoading, size=null, children}) => {
  const loadVisibility = isLoading ? 'visible' : 'hidden';
  const childrenOpacity = isLoading ? 0 : 1;
  return(
    <div style={{ position: 'relative', width: '100%' }}>
      <div style={{ visibility: loadVisibility }}>
        <CircularProgress 
          style={{
            color: '#26c98c',
            position: 'absolute',
            top: '10px',
            left: '50%',
            height: size || '40px',
            width: size || '40px',
          }}
        />
      </div>
      <div style={{ opacity: childrenOpacity }}>
        {children}
      </div>
    </div>
  )
}
export default Load;
