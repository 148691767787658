import React  from 'react';
import { styled } from '@mui/system';
import { Table, TableBody, TableRow, TableHead,
  TableCell } from '@mui/material';
import { makeUrl } from '@clients/shared/src/utils';
import { Link } from '../main';

const PREFIX = 'PlacesStocksTable';

const classes = {
  highlight: `${PREFIX}-highlight`
};

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${classes.highlight}`]: {
    '& td': {
      fontWeight: 500,
      color: '#d92b2b',
    },
  }
}));

/** Page showing places' stock and return rate. */
export default function PlaceAnalytics ({stocks}) {

  const places = stocks
    .filter(p => p.older_cnt)
    .map(p =>({...p, cnt:p.older_cnt, stock:p.older_stock}))
    .map(({id, name, cnt, stock}) => ({
      id, name, stock, cnt,
      returns_cnt: cnt - stock,
      rate: cnt ? Math.round(100*(1-stock/cnt)) : -1,
    }));
  const sorted = stableSort(places);
  const url = makeUrl('/events/history', {showDeliveries: true});
  const total = places.reduce((tot, place) => tot + place.cnt, 0);
  const stock = places.reduce((tot, place) => tot + place.stock, 0);
  const rate = Math.round((total-stock)*100/total);
  if (places.length===0) {
    return <p>Aucune livraison trouvée.</p>
  }
  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell><strong>Nom</strong></TableCell>
          <TableCell><strong>Bacs Livrés</strong></TableCell>
          <TableCell><strong>Bacs rendus</strong></TableCell>
          <TableCell><strong>Bacs non rendus</strong></TableCell>
          <TableCell><strong>Tx retour</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {sorted.map(place => (
        <TableRow 
          key={place.id} 
          hover 
          className={(place.rate<75) ? classes.highlight : ''}
        >
          <TableCell>
            <Link to={url + '&text=' + place.name}>
              <strong>{place.name}</strong> 
            </Link>
          </TableCell>
          <TableCell>{place.cnt}</TableCell>
          <TableCell>{place.returns_cnt}</TableCell>
          <TableCell>{place.stock}</TableCell>
          <TableCell>{place.rate} %</TableCell>
        </TableRow>))}
        <TableRow hover>
          <TableCell><strong>Total</strong></TableCell>
          <TableCell><strong>{total}</strong></TableCell>
          <TableCell><strong>{total - stock}</strong></TableCell>
          <TableCell><strong>{stock}</strong></TableCell>
          <TableCell><strong>{rate + " %"}</strong></TableCell>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
}

const stableSort = array => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = b[0]['stock'] - a[0]['stock'];
    return ((order !== 0) ? order : a[1] - b[1]);
  });
  return stabilizedThis.map(el => el[0]);
};
