import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableBody, TableCell,
  TableRow } from '@mui/material';
import { isPast, isAfter } from 'date-fns';
import { useApi, stringifyDate, makeUrl } from '@clients/shared/src/utils';
import { Page, IconButton } from '../main';
import { Load }  from '../_utils';


/** Table of leases. */
export default function Leases() {
  const [ leases, setLeases ] = useState([]);
  const api = useApi();
  useEffect(() => {api.get('/leases?active=True')
    .then(sortLeases).then(setLeases);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title='Contrats de location'
      options={<IconButton type='add' to='/leases/create'/>}
    >
    <Load isLoading={api.isLoading}>
      <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
        <Table> 
          <TableHead> 
            <TableRow>
              <TableCell> Destinataire </TableCell>
              <TableCell> Quantié </TableCell>
              <TableCell> Modèle </TableCell>
              <TableCell> État </TableCell>
              <TableCell> Actions </TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
          {leases.map(l => (
            <TableRow key={l.id}>
              <TableCell> {l.client} </TableCell>
              <TableCell> {l.bac_count} </TableCell>
              <TableCell> {l.model} </TableCell>
              <TableCell> 
                {l.end 
                  ? `préparé le ` + shortDate(l.end)
                  : l.validated 
                    ? `validé le ` + shortDate(l.validated)
                    : `créé le ` + shortDate(l.created)
                }
              </TableCell>
              <ActionsCell lease={l}/>
            </TableRow>
          ))}
          </TableBody>
        </Table>
        {(leases.length===0) && <h3>Aucun contrat trouvé</h3>}
      </Box>
    </Load>
    </Page>);
};


const ActionsCell = ({ lease: {id, client_id, model_id} }) =>
  <TableCell>
    <IconButton type='settings' to={'/leases/'+id}/>
    <IconButton
      type='duplicate'
      to={makeUrl('/leases/create', {client_id, model_id})}/>
    <IconButton type='analyse' to={`/places/${client_id}/get-bacs-analysis`}/>
  </TableCell>

const sortLeases = leases => leases.sort((a, b) => 
  a.client.localeCompare(b.client, 'fr', {'sensitivity':'base'})
  || (a.model || '').localeCompare(b.model, 'fr',{'sensitivity':'base'})
  || isAfter(new Date(a.created), new Date(b.created)));

const shortDate = date => stringifyDate(new Date(date), {month: 'short'})
