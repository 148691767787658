import * as Sentry from '@sentry/react';
import { requestCanceledMsg } from '@clients/shared/src/utils';

const { NODE_ENV, REACT_APP_RELEASE } = process.env;
if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://77dfd39aa51b4cc38647e91541e6ba5f@o243363.ingest.us.sentry.io/1418960",
    integrations: [
      Sentry.replayIntegration(),
    ],
    release: REACT_APP_RELEASE,
    ignoreErrors: [new RegExp(`.*${requestCanceledMsg}.*`, 'i')],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.05,
  });
}
