import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import qs from 'query-string';
import { useApi, makeUrl } from '@clients/shared/src/utils';
import { useUpdatePlace } from '@clients/shared/src/place/services';
import { FormPage, SubmitButton, Card } from '../main';

/** The form to add a ref of a place. */
export default function AddRef ({match, location}) {
  const { place_id: target_id } = match.params;
  const [ placeRef, setPlaceRef ] = useState({target_id, ref:''});
  const updatePlace = useUpdatePlace();
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const api = useApi();
  const { name, workplace_id } = qs.parse(location.search);
  useEffect(() => {
    api.get().then(refs => refs.length && setPlaceRef(refs[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target_id])
  useEffect(() => {
    (placeRef.ref !== '') && updatePlace(target_id, 'ref', placeRef.ref);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target_id, placeRef.ref]);
  const submit = values => (values.id 
    ? api.put('/placerefs/' + values.id, values) 
    : api.post(makeUrl('/placerefs', {workplace_id}), values))
    .then(setPlaceRef).then(() => setSucceeded(true));
  return (
    <FormPage title={`Votre référence interne pour ${name || target_id}`}>
      <Card title={`${placeRef.id ? 'Modification' : 'Ajout'} d'une réf`}>
        <Formik onSubmit={submit} initialValues={placeRef} enableReinitialize>
          <Form>
            <Field
              name='ref'
              label='Référence'
              component={TextField}
              margin='dense'
              variant="standard"
              fullWidth
              required
            />
            <SubmitButton {...{submitSucceeded}}/>
          </Form>
        </Formik>
      </Card>
    </FormPage>)
};
