import React, { useEffect, useState } from 'react';
import { compareDesc } from 'date-fns'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow,
  } from '@mui/material';
import { stringifyDate, useApi } from '@clients/shared/src/utils';
import { Page, IconButton } from '../main';
import { Load } from '../_utils';


/** The list of all pickups. */
export default function Pickups() {
  const [pickups, setPickups] = useState([]);
  const api = useApi();
  useEffect(() => {
    api.get().then(sortByPlanedLoading).then(setPickups);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title='Collectes'
      options={<IconButton type='add' to='/pickups/create'/>}
    >
      <Load isLoading={api.isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto' >
          <Table> 
            <TableHead> 
              <TableRow>
                <TableCell>Collecte</TableCell>
                <TableCell>Prévu le</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Nombre de bacs</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead> 
            <TableBody>
            {pickups.map((pickup)=>(
              <TableRow key={pickup.id}>
                <TableCell>
                  {pickup.place}
                </TableCell>
                <TableCell>
                  {stringifyDate(new Date(pickup.planed_loading))}
                </TableCell>
                <TableCell>
                  {pickup.destination}
                </TableCell>
                <TableCell>
                  {pickup.bac_count}
                </TableCell>
                <TableCell>
                  <IconButton
                    type='edit'
                    to={'/pickups/'+pickup.id}
                  />
                  <IconButton
                    type='duplicate'
                    to={{pathname: '/pickups/create', state: pickup}}
                  />
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          {(pickups.length===0) && <h3>Aucune collecte.</h3>}
          </Box>
      </Load>
    </Page>
  );
};

const sortByPlanedLoading = pickups => pickups.sort((a, b) =>
  compareDesc(new Date(a.planed_loading), new Date(b.planed_loading))
)
