import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { CloudDownload } from '@mui/icons-material';
import { Box, Card, ListItemButton, ListItemIcon, ListItemText, Typography
} from '@mui/material';
import { stdDate, useApi, makeUrl } from '@clients/shared/src/utils';
import { Load } from '../_utils';
import { Page } from '../main';
import { SearchBar, useGetModels, useSearch, } from '../searchbar';
import LostTable from './LostTable';

/** Page showing the number of bacs lost by place and by caretaker */
export default function LostAnalytics ({location}) {
  const api = useApi({disableConcurrentRequest: true});
  const [tableDestinations, setTableDestinations] = useState([]);
  const [tableCaretakers, setTableCaretakers] = useState([]);
  const search = qs.parse(location.search)
  const models = useGetModels(search);

  useEffect(() => {
    api.get().then(data => {setTableDestinations(data.by_place);
                            setTableCaretakers(data.by_caretaker)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  const exportUrl = makeUrl('/api/export/lost/'+ search.workplace_id, search);
  const filters = [
    {template: 'workplace'},
    {template: 'after', initialValue: stdDate(-90)},
    {template: 'before'},
    {template: 'models', items: models}
  ];
  const [searchBarProps, ] = useSearch(filters, []);

  return(
    <Page title='Synthèse des bacs perdus'> 
      <SearchBar {...searchBarProps}/>
      <Load isLoading={api.isLoading}>
        <Box width='90%' mx='auto' my={6}>
          {tableDestinations.length ? 
          <LostTable 
            header={[
              {id:'name', label:'Point de livraison'},
              {id:'lost_cnt', label:'Bacs perdus'},
            ]} 
            body={tableDestinations}
          />
          : 'Aucun bac perdu sur cette période.'}
        </Box>
        {tableCaretakers.length ?
        <Box width='90%' mx='auto' mt={2}>
          <Typography>...dont loués par :</Typography>
          <LostTable 
            header={[
              {id:'name', label:'Locataire du bac'},
              {id:'lost_cnt', label:'Bacs perdus'},
            ]} 
            body={tableCaretakers}
          />
        </Box>
        : null}
        <Box component={Card} maxWidth={300} my={6} mx='auto' p={2}>
          <ListItemButton component={'a'} href={exportUrl} download>
            <ListItemIcon><CloudDownload/></ListItemIcon>
            <ListItemText>Exporter les données</ListItemText>
          </ListItemButton>
        </Box>
      </Load> 
    </Page>);
};
