import React from 'react';
import { Card, Grid, Tooltip, Badge, Avatar } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { Link, H5, Sub1, Sub2 } from '../main';


/** Display a place as a card. */
export default function PlaceCard ({ place }) {
  const isNew = differenceInDays(new Date(), new Date(place.created)) < 14;
  const initials = ({name}) => name.split(' ').slice(0, 2)
    .map(w => w[0]).join('').toUpperCase();

  return (
    <Grid item m={2}>
      <Link to={`places/${place.id}`}>
        <Card>
          <Grid container sx={{...contentSx, ...(isNew && highlightSx)}}>
            <Avatar sx={avatarSx}>
              {initials(place)}
            </Avatar>
            <Grid item xs sx={textContainerSx}>
              <Tooltip placement='top-end' title={<Sub2>{place.name}</Sub2>}>
                <H5 sx={{...textSx, ...titleSx}} noWrap>
                  <Badge
                    badgeContent={'new'}
                    color="primary"
                    invisible={!isNew}
                    sx={{marginLeft: 4, marginRight: 5}}
                  />
                  {place.name}
                </H5>
              </Tooltip>
              <Sub1 sx={textSx}>
                {place.city} - {place.postal_code}
              </Sub1>
              <Sub1 sx={textSx}>
                {place.ref}
              </Sub1>
            </Grid>
          </Grid>
        </Card>
      </Link>
    </Grid>
  );
}

const contentSx = {
  alignItems: 'center',
  height: 100,
  justifyContent: 'center',
  padding: 3,
}

const highlightSx = {
  border: 'solid 4px',
  borderColor: 'secondary.main',
}

const textContainerSx = {
  marginLeft: 2,
  overflow: 'hidden'
}

const textSx = {
  textAlign: 'right',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const titleSx = {
  fontWeight: 1000,
  color: 'text.secondary',
}

const avatarSx = {
  width: 50,
  height: 50,
  fontSize: 22,
  bgcolor: 'secondary.main',
}
