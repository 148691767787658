import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useApi } from '@clients/shared/src/utils';
import { FormPage, SubmitButton, Card } from '../main';
import PasswordMeter from './PasswordMeter';

/** The change password form. */
export default function Password ({location}) {
  const api = useApi();
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const submit = (values, {resetForm}) => api.put(null, values)
    .then(() => setSucceeded(true), e=>({e}));
  const initial = {new:'', confirmation:''};
  const { fullName } = location.state || {};
  return (
    <FormPage title='Changer le mot de passe'>
      <Card mt={10} title={fullName || 'Mot de passe'}>
        <Formik initialValues={initial} onSubmit={submit} validate={validate}>
          <Form>
            {[{name: 'new', label: 'Nouveau mot de passe',},
              {name: 'confirmation', label: 'Confirmation',},
            ].map(({name, label}) => (
            <React.Fragment key={name}>
              <Field
                component={TextField}
                name={name}
                label={label}
                type='password'
                required
                fullWidth
                margin='normal'
                variant="standard"
              />
              {name==='new' && <PasswordMeter name='new'/>}
            </React.Fragment>))}
            <SubmitButton showSuccess={submitSucceeded}>
              Valider
            </SubmitButton>
          </Form>
        </Formik>
      </Card>
    </FormPage>);
};

const validate = values => {
  const errors = {};
  if (values.new!==values.confirmation) {
    errors.confirmation = 'Les deux mots de passe doivent être identiques.'
  }
  return errors;
};
