import { useDispatch, useSelector } from 'react-redux';
import { removeDestination, setBacEmpty, setBacClean, setDestination
  } from '../redux/actions';
import { getDestination, getBacEmpty, getBacClean } from '../redux/selectors';


// bac_empty services

/** Set bac_empty */
export const useSetBacEmpty = () => {
  const dispatch = useDispatch();
  return bacEmpty => dispatch(setBacEmpty(bacEmpty)); 
}

/** Get bac_empty */
export const useGetBacEmpty = () => {
  return useSelector(state => getBacEmpty(state.rootReducer))
}


// bac_clean services

/** Set bac_clean */
export const useSetBacClean = () => {
  const dispatch = useDispatch();
  return bacClean => dispatch(setBacClean(bacClean)); 
}

/** Get bac_clean */
export const useGetBacClean = () => {
  return useSelector(state => getBacClean(state.rootReducer))
}


// Destination services

/** Set the destination. */
export function useSetDestination() {
  const dispatch = useDispatch();
  return (place) => dispatch(setDestination(place));
}

/** Remove the destination. */
export function useRemoveDestination() {
  const dispatch = useDispatch();
  return () => dispatch(removeDestination());
}

/** Get the current destination. */
export function useGetDestination() {
  return useSelector(state => getDestination(state.rootReducer))
}
