import React, { useEffect } from 'react';
import { useApi } from '@clients/shared/src/utils';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { FormPage } from '../main';
import ExtraSettings from './ExtraSettings';
import PlaceForm from './PlaceForm';

/** Page to edit an existing place and to link place's related pages. */
export default function PlaceSettings ({match, history}) {
  const placeId = match.params.place_id;
  const [ place, setPlace ] = usePlaceOrState(placeId);
  const api = useApi();

  useEffect(() => {placeId && api.get('/places/' + placeId).then(setPlace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  return(
    <FormPage 
      title={ place?.name || 'Nouveau client'}
      subtitle={placeId ? 'Editer' : null}
    >
      <PlaceForm {...{place, setPlace, history}} />
      {place && <ExtraSettings {...{place, history}} />}
    </FormPage>
  );
};
