import React, { useEffect, useState } from 'react';
import { fr } from 'date-fns/locale'
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import { Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Field, useField } from 'formik';
import { MobileDatePicker } from 'formik-mui-lab';

export default function DatePickerComplete ({
  xs, label, clearValue, required, error, ...props
}){
  const [ { value } ,,{ setValue } ] = useField(props.name);
  const [selectedDate, setSelectedDate]=useState(value ? parseISO(value) : null)
  const adaptDate = props.name === 'before' ? endOfDay : startOfDay
  useEffect(() => {
    setSelectedDate(value ? parseISO(value) : null)
  }, [value]);

  return (
    <Grid item xs={xs}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
        <Field
          component={MobileDatePicker}
          inputFormat='d MMM yyyy'
          toolbarFormat='d MMM yyyy'
          toolbarTitle=''
          disableMaskedInput
          renderInput={params => (
            <TextField fullWidth {...params} {...{label, required, error}}
              variant='standard'
              InputProps={{
                endAdornment:
                (clearValue !== undefined) && selectedDate &&
                  <InputAdornment position="end">
                    <IconButton edge='end' onClick={() => setValue(clearValue)}>
                      <HighlightOff />
                    </IconButton>
                  </InputAdornment>,
              }}
            />
          )}
          okText=''
          cancelText=''
          DialogProps={{sx:dialogStyles}}
          closeOnSelect={true}
          allowSameDateSelection
          value={selectedDate}
          onDateChange={setSelectedDate}
          onAccept={() => setValue(selectedDate
            ? adaptDate(selectedDate).toISOString()
            : (clearValue === undefined) ? '' : clearValue
          )}
          {...props}
        />
      </LocalizationProvider>
    </Grid>
  )
};


const dialogStyles = {
  '& .PrivatePickersToolbar-root':{
    backgroundColor: 'primary.main',
    py: 6,
    px: 3
  },
  '& .PrivateDatePickerToolbar-penIcon':{
    display: 'none'
  }
}
