import React from 'react';
import { Card, Box, MenuItem } from '@mui/material';
import Link from './Link';
import Page from './Page';
import { H5 } from './Typography';

/** A menu for superadmin pages. */
export default function Superadmin() {
  return (
    <Page title="Superadmin">
      <Box component={Card} 
        width={{xs:0.9, md:0.5}} 
        my={{xs:4, md:16}} 
        mx='auto'
        p={2}
      >
        {links.map(link =>
        <Link key={link.uri} to={link.uri}>
          <MenuItem xs={12}>
            <H5>{link.text}</H5>
          </MenuItem>
        </Link>)}
      </Box>
    </Page>);
};

const links = [
  {uri: '/search/places', text: 'Rechercher un point de livraison'},
  {uri: '/messages-settings', text: 'Mails automatiques'},
  {uri: '/situation-mails', text: 'Mails situation'},
  {uri: '/roles', text: 'Liste des rôles'},
];
