import React, { useEffect, useState } from 'react';

export const useIsOnline = () => {
  const [ isOnline, setIsOnline ] = useState(window.navigator.onLine);
  const setOffline = event => setIsOnline(false);
  const setOnline = event => setIsOnline(true);
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);
  return isOnline;
}

// nb: on Safari, online/offline events are not as straight as on others main
// browsers. For instance, if a phone is cable connected to the computer, Safari
// always consider connection to be online. Even if phone doesn't provide any
// connection and computer can't reach internet.
// Same behaviour with window.navigator.onLine.