import { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import 'promise-polyfill/src/polyfill'; //promise polyfill for IE
import Sentry from './Sentry';
import { createApiUrl } from './apiUtils';
import { useSetErrorMsg } from './services';

export const requestCanceledMsg = 'Request canceled';

const requestOptions = {
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json',
  },
  credentials: 'same-origin',
};

const respSuccess = (resp) => {
  console.log(resp.config.method, resp.config.url, resp.status);
  return resp.data;
};

const getServerErrorMessage = (error) => {
  const {data, status} = error.response;
  const statusMessages = {
    400: "Erreur 400 : Corrigez la requête.",
    401: "Erreur 401 : Veuillez vous identifier.",
    403: "Erreur 403 : Vous n'avez pas accés a cette page.",
    404: "Erreur 404 : L'url demandé n'existe pas.",
    409: "Duplicate event.",
    500: "Erreur 500 : Erreur du serveur, contactez nous via contact@pandobac.com.",
    502: "Erreur 502 : Le serveur ne répond pas, contactez nous via contact@pandobac.com",
    504: "Erreur 504 : Délai réponse serveur expiré, contactez nous via contact@pandobac.com",
  };
  return (
    data.error?.message 
    || statusMessages[status] 
    || `Erreur ${status} : contactez nous via contact@pandobac.com.`
  );
}

const  handleServerError = (error, setErrorMsg) => {
  Sentry.withScope(scope => {
    scope.setLevel('warning')
    Sentry.captureException(error);
  });
  const message = getServerErrorMessage(error);
  if (error.response?.status !== 409) {
    console.log(error.response);
    setErrorMsg(message);
  }
  return message;
};

function respError(error, {setErrorMsg}) {
  const { response, request } = error;
  let message = error.message || '';
  if (axios.isCancel(error)) {
    message = requestCanceledMsg;
  } else if (response) {
    message = handleServerError(error, setErrorMsg);
  } else if (request) {
    message = 'Vous rencontrez un problème de réseau.';
  }
  console.log(message);
  throw Error(message, {cause: {status: request.status}});
};

function apiFetch(method, urn, data, options) {
  const { setLoading, abordPendingRequest, isMounted } = options;
  setLoading(true);
  const url = createApiUrl(urn);
  const signal = abordPendingRequest();
  return axios({...requestOptions, url, method, data, signal})
    .then(resp => {
      if (isMounted.current === false) {
        throw new Error('Component unmounted before receiving response.');
      }
      return resp;})
    .then(resp => respSuccess(resp))
    .then(resp => {
      isMounted.current && setLoading(false)
      return resp && resp.data})
    .catch(error => {
      !axios.isCancel(error) && isMounted.current && setLoading(false)
      respError(error, options)})
    ;
};

export default function useApi(extraOptions={}) {
  const [isLoading, setLoading] = useState(false);
  const setErrorMsg = useSetErrorMsg();
  const isMounted = useRef(true);
  const controllerRef = useRef(new AbortController());
  const abordPendingRequest = () => {
    if (extraOptions.disableConcurrentRequest){
      controllerRef.current.abort();
      controllerRef.current = new AbortController();
    }
    return controllerRef.current.signal;
  };
  const options = {setLoading, setErrorMsg, isMounted, 
    abordPendingRequest, controllerRef, ...extraOptions};
  useEffect(() => () => controllerRef.current.abort(), []);
  useEffect(() => () => {isMounted.current=false}, []);
  return ({
    isLoading,
    get: useMemo(() => (urn) => apiFetch('get', urn, undefined, options),[]),
    put: (urn, data) => apiFetch('put', urn, data, options),
    post: (urn, data) => apiFetch('post', urn, data, options),
    delete: (urn, data) => apiFetch('delete', urn, data, options), 
  });
};
