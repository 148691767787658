import React from 'react';
import { useApi } from '@clients/shared/src/utils';
import { Card } from '../main';
import Universal from '../scan/Universal';

/** The form to add a given model to a bac. */
export default function AddBac ({modelId: model_id, addBacCnt}) {
  const api = useApi();
  const handleResult = bac_shown_id => (
    api.put('/bacs/' + bac_shown_id + '/model', {model_id})
      .then(data => addBacCnt(data.count))
  );
  return (
    <Card>
      <Universal handleResult={handleResult}/>
    </Card>)
};
