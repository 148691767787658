import React, { useState } from 'react';
import { TableContainer, Paper, Table, TableBody, TableRow, TableHead,
  TableCell, IconButton } from '@mui/material';
import { CallMade, UnfoldMore, UnfoldLess } from '@mui/icons-material';
import useToursState from '@clients/shared/src/tour/toursState';
import { stdDate, makeUrl } from '@clients/shared/src/utils';
import { Link } from '../main';

export default function PositionsTable ({data, models, places, place}) {
  const [ showTours, setShowTours ] = useState(false);
  const { tours } = useToursState(place.id);
  let tableRows = data.map(row => ({...row, ...dataDisplay[row.key]}));
  const i = tableRows.findIndex(row => row.key==='clients');
  const tourRows = tours.map(tour => ({
    label: ' > ' + tour.name,
    key: tour.id, 
    data: groupByTour(tour.places, places, models),
    italic: true,
    to: dataDisplay.clients.to,
    search: {tourId: tour.id},
  }));
  showTours && tableRows.splice(i+1, 0, ...tourRows);
  tableRows = tableRows.map(row => updateSearch(row, place.id));
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {models.map(({id, name}) =>
            <TableCell key={id}>{name}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map(({label, key, to, search, data, italic}) => 
          <TableRow key={key} hover>
            <TableCell {...(italic ? {style: {fontStyle: 'italic'}} : {})}>
              {to ? <VisualLink {...{to, search, label}}/> : label}
              {(key==='clients' && tours.length>0) &&
                  <ShowToursBtn {...{showTours, setShowTours}}/>}
            </TableCell>
            {data.map((value, index) =>
            <TableCell key={index}>{value}</TableCell>
            )}
          </TableRow>
        )}
        </TableBody>
      </Table>
    </TableContainer>);
};

const VisualLink = ({to, search, label}) => (
  <Link to={makeUrl(to,search)}>
    {label} <CallMade fontSize='small' style={{marginBottom: '-4px'}}/>
  </Link>);

const ShowToursBtn = ({setShowTours, showTours}) => (
  <IconButton onClick={() => setShowTours(bool => !bool)} size='small'>
    {showTours ? <UnfoldLess/> : <UnfoldMore/>}
  </IconButton>);

const dataDisplay = {
  stock: {
    to:'/events/history', 
    search: {showReturns:true, after: stdDate(-30),},
  },
  stockNotClean: {
    to:'/events/history', 
    search: {showReturns:true, after: stdDate(-30), is_clean: false},
  },
  clients: {
    to:'/reports/places',
    search: {after: stdDate(-33), before: stdDate(-2)},
  },
  lost: { 
    to:'/reports/lost', 
    search: {after: stdDate(-90), before: stdDate(),},
  },
};

const groupByTour = (tourPlaces, placesData, models) => (
  models.map(model => (
    tourPlaces.reduce((cnt, {id}) => {
        const data = placesData.find(place => place.id===id) || {}
        return cnt + (data[model.id] || 0)}
      , 0)
  )));

const updateSearch = (row, workplace_id) => {
  const { search } = row;
  if (search===undefined) {
    return row
  }
  return {
    ...row,
    search: {...row.search, workplace_id},
  }
};
