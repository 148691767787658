/** Pluralizes every word of a string except those starting with '$' */

export function pluralize (string, count) {
  const words = string.split(' ');
  const pluralizedWords = words.map(word => {
    if(count === 0 || count === 1) {
      return (word.charAt(0) !== '$') ? word : word.slice(1)
    } else {
      return (word.charAt(0) !== '$') ? word + 's' : word.slice(1)
    }
  })
  return count + ' ' + pluralizedWords.join(' ') 
}
