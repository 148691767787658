import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Box } from '@mui/material';
import { useApi, pluralize } from '@clients/shared/src/utils';
import { FormPage, SubmitButton, Card } from '../main';
import AddBac from './AddBac';

/** The form to edit a specific model. */
export default function BacModel ({match, history}) {
  const [ model, setModel ] = useState(
    fields.reduce((d, field) => ({...d, [field.name]: field.default}), {}));
  const [ bacCnt, setBacCnt ] = useState(0);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const api = useApi();
  const modelId = match.params.model_id;
  useEffect(() => {modelId && api.get().then(model => {
      setModel(model); setBacCnt(model.count);});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  const addBacCnt = cnt => setBacCnt(bacCnt => bacCnt + cnt);
  const submitNew = values => api.post('/models', values)
    .then(model => history.push('/models/' + model.id));
  const submitUpdate = values => api.put(null, values)
    .then(() => setModel(values))
    .then(() => setSucceeded(true), () => setSucceeded(false));
  const submit = modelId ? submitUpdate : submitNew;

  return (
    <FormPage title={modelId ? 'Modèle '+model.name : 'Nouveau modèle'}>
      <Card title={`${modelId ? 'Modification du' : 'Nouveau'} modèle`}>
        <Formik onSubmit={submit} initialValues={model} enableReinitialize>
          <Form>
            {fields.map(({name, label, isRequired}) => 
            <Field
              key={name}
              component={TextField}
              name={name}
              label={label}
              required={isRequired || false}
              margin='dense'
              variant="standard"
              fullWidth
            />)}
            <SubmitButton {...{submitSucceeded}}/>
          </Form>
        </Formik>
      </Card>
      {modelId &&
      <Box mt={8}>
        <AddBac modelId={modelId} addBacCnt={addBacCnt}/>
        <div>{pluralize('bac enregistré', bacCnt)+' avec ce modèle'}</div>
      </Box>}
    </FormPage>);
};

const fields = [
  {name:'name', label:'Nom', isRequired:true, default:''},
  {name:'brand', label:'Marque', default:''},
  {name:'color', label:'Couleur', default:''},
  {name:'length', label:'Longueur (mm)', default:0},
  {name:'width', label:'Largeur (mm)', default:0},
  {name:'height', label:'Hauteur (mm)', default:0},
];
