import React from 'react';
import qs from 'query-string';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Box, Tooltip
  } from '@mui/material';
import useToursState from '@clients/shared/src/tour/toursState';
import { usePlace } from '@clients/shared/src/place/services';
import { Link, Page, IconButton } from '../main';
import TourPlaceCheckbox from './TourPlaceCheckbox';


/** Page displaying workplace's tours. */
export default function Tours({location}) {
  const {place_id, workplace_id}=qs.parse(location.search,{parseNumbers:true});
  const { tours } = useToursState(workplace_id);
  const { name } = usePlace(place_id) || {};

  return (
    <Page
      title={place_id ? 'Tournées pour ' + name : 'Tournées'}
      options={<IconButton
        type='add'
        to={'/tours/create'+(workplace_id ? '?workplace_id='+workplace_id:'')}
      />}
    >
    <Paper component={Box} m={{xs:2, sm:4, md:8}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Lieux</TableCell>
            <TableCell>Chauffeurs</TableCell>
            {place_id && <TableCell>Appartenance</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {tours.map(({id, name, places, drivers}) =>
          <TableRow key={id}>
            <TableCell><Link to={'/tours/'+id}>{name || id}</Link></TableCell>
            <Tooltip title={places.map(place => place.name + ', ')}>
              <TableCell>{places.length}</TableCell>
            </Tooltip>
            <Tooltip 
              title={drivers.map(driver => driver.name+', ')}
            >
              <TableCell>{drivers.length}</TableCell>
            </Tooltip>
            {place_id &&
            <TableCell>
              <TourPlaceCheckbox placeId={place_id} tour={{id, places}}/>
            </TableCell>}
          </TableRow>)}
        </TableBody>
      </Table>
    </Paper>
    </Page>);
};
