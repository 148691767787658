import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Redirect } from "react-router-dom";
import { Button } from '@mui/material';
import { pages } from './pages';
import './qr.css';

export const hostUrl = 'https://www.pando.ovh';

export const NumberField = props => (
  <Field
    component={TextField}
    className='no-print'
    required
    type='number'
    variant="standard"
    parse={value => value==='' ? null : Number(value)}
    style={{width: '100px', marginRight: '35px'}}
    inputProps={{step: props.step || 1}}
    {...props}
  />
);

/** Page to create QR code stickers. */
export default function QRGrid ({match}) {
  const [ qrOptions, setQrOptions ] = useState({
    prefix: 'X',
    startAt: 1,
    repeat: 2,
    size: 12,
    col: 3,
    marginTop: 2,
    marginLeft: 9.5,
    rowGap: 8.8
  })
  const QRgrid = document.querySelector('.QRgrid');
  if (QRgrid) {
    QRgrid.style.setProperty('--printMarginTop', qrOptions.marginTop +'mm');
    QRgrid.style.setProperty('--printMarginLeft', qrOptions.marginLeft +'mm');
    QRgrid.style.setProperty('--rowGap', qrOptions.rowGap +'mm');
  }

  const handleChange = (event, values) => {
    if (event.target.name === 'marginTop') {
      const marginTop = event.target.value;
      setQrOptions({...values, marginTop});
    } else if (event.target.name === 'marginLeft') {
      const marginLeft = event.target.value;
      setQrOptions({...values, marginLeft});
    } else if (event.target.name === 'rowGap') {
      const rowGap = event.target.value;
      setQrOptions({...values, rowGap});
    }
  }

  const nextPage = (values) => {
    const startAt = values.startAt + values.size;
    setQrOptions({...qrOptions, startAt});
  }

  const pageName = match.params.page_name;
  const page = pages[pageName];
  if (page !== undefined) {
    const Content = page.component;
    return(
      <div className='QR'>
        <h1 className='no-print'>QR Code creator</h1>
        <Formik onSubmit={nextPage} initialValues={qrOptions}>
          {({values}) => (
        <Form onSubmit={nextPage} onChange={event => handleChange(event, values)}>
          <NumberField name='startAt' label='Début'/>
          <NumberField name='size' label='Nb codes'/>
          <NumberField name='col' label='Nb de col.'/>
          <NumberField name='repeat' label='Répétition'/>
          <br />
          <NumberField name='marginTop' label='MargeH mm'/>
          <NumberField name='marginLeft' label='MargeG mm'/>
          <NumberField name='rowGap' label='Interligne'/>
          <span className='no-print'>
          <Button 
            onClick={() => nextPage(values)} 
            style={{verticalAlign: 'bottom'}}
          >
            Page suivante
          </Button>
          </span>
          <br />
          <Content
            size={values.size}
            startAt={values.startAt}
            col={values.col}
            repeat={values.repeat}
            prefix={values.prefix}
            pageName={page.name}
            pagePath={page.path}
          /> 
        </Form>)}
      </Formik>
    </div>
  )}
  else {
    return <Redirect to={'/qr'}/>
  }
}
