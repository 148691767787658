import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { Box, Paper, Table, TableBody, TableRow, TableHead,
  TableCell, } from '@mui/material';
import { stdDate, useApi, makeUrl } from '@clients/shared/src/utils';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { Load } from '../_utils';
import { SearchBar, useSearch } from '../searchbar';
import { Page } from '../main';

/** Page showing a place stock and return rate, detailed by models. */
export default function PlaceAnalytics ({location, match}) {
  const [events, setEvents] = useState([]);
  const { after, before } = qs.parse(location.search, {parseNumbers:true});
  const [place, ] = usePlaceOrState(match.params.place_id);
  const api = useApi();
  useEffect(() => {
    const url = makeUrl('/events', {destination_id: place.id, after, before})
    api.get(url).then(setEvents);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place.id, after, before]);

  const models = Object.values(events.reduce(
    (modelsD, {bac_model:name, closed}) => {
      const model = modelsD[name]
      if (model===undefined) {
        modelsD[name] = {name, cnt: 1, stock: closed ? 0 : 1};
      } else {
        model.cnt += 1
        model.stock += closed ? 0 : 1
      }
      return modelsD
    }, {}))
    .sort(({name: a}, {name: b}) => (a || 'z').localeCompare(b))
    .map(({name, cnt, stock}) => ({
      name, stock, cnt,
      returns_cnt: cnt - stock,
      rate: cnt ? Math.round(100*(1-stock/cnt)) : -1,
    }))
    
  const total = models.reduce((tot, place) => tot + place.cnt, 0);
  const stock = models.reduce((tot, place) => tot + place.stock, 0);
  const rate = Math.round((total-stock)*100/total);
  const filters = [
    {template: 'after', initialValue: stdDate(-30)},
    {template: 'before'},
  ]
  const [searchBarProps, ] = useSearch(filters, []);
  
  return(
    <Page title='Synthèse client' subtitle={place.name}> 
      <SearchBar {...searchBarProps}/> 
      <Load isLoading={api.isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
          {models.length ? 
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Modèle</TableCell>
                <TableCell>Livrés</TableCell>
                <TableCell>Renvoyés</TableCell>
                <TableCell>Non renvoyés</TableCell>
                <TableCell>Tx retour</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {models.map(({name, cnt, returns_cnt, stock, rate}) => (
              <TableRow hover key={name}>
                <TableCell><strong>{name || 'sans modèle'}</strong></TableCell>
                <TableCell>{cnt}</TableCell>
                <TableCell>{returns_cnt}</TableCell>
                <TableCell>{stock}</TableCell>
                <TableCell>{rate} %</TableCell>
              </TableRow>))}
            {models.length>1 && 
              <TableRow hover>
                <TableCell><strong>Total</strong></TableCell>
                <TableCell><strong>{total}</strong></TableCell>
                <TableCell><strong>{total - stock}</strong></TableCell>
                <TableCell><strong>{stock}</strong></TableCell>
                <TableCell><strong>{rate + " %"}</strong></TableCell>
              </TableRow>}
            </TableBody>
            </Table> : <Box p={4}>Aucune livraison trouvée</Box>}
        </Box>
      </Load>
    </Page>);
};
