import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import { Box, MenuItem } from '@mui/material';
import { useApi, stringifyDateTime } from '@clients/shared/src/utils';
import { FormPage, SubmitButton, Card } from '../main';
import PlaceAutocomplete from '../place/PlaceAutocomplete';
import UserAutocomplete from '../user/UserAutocomplete';
import EditWarning from './EditWarning';
import descriptions from './descriptions';

/** Form to edit a message setting. */
export default function MessageSettings ({match, history, location}) {
  const api = useApi();
  const [ formValues, setFormValues ] = useState(empty);
  const [ messageTypes, setMessageTypes ] = useState([]);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const [ isOpen, setIsOpen ] = useState(false);
  const settingsId = match.params.settings_id;
  useEffect(() => {
    api.get('/message-types')
      .then(mts => 
        options.map(option => {
          const mt = mts.find(mt => mt.name===option.name)
          return {...mt, ...option};
        })
      )
      .then(setMessageTypes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (settingsId) {
      api.get().then(setFormValues);
    } else {
      setFormValues({
        ...empty,
        ...location.state,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsId, setFormValues, location]);
  const {id: formSettingsId, last_sent} = formValues;
  useEffect(() => {
    if (!settingsId && submitSucceeded===true) {
      history.replace('/messages-settings/' + formSettingsId);
    }
  }, [history, settingsId, submitSucceeded, formSettingsId]);
  const toggleWarning = () => {setIsOpen(value => !value)};
  const title=(settingsId ?'Modification':'Création')+' d\'un envoi périodique';
  const submit = values => {
    if (values.last_sent && !isOpen) {
      toggleWarning()
      return false;
    }
    setIsOpen(false);
    return ((!values.id) ? 
      api.post('/messages-settings', values) : api.put(null, values))
    .then(setFormValues)
    .then(() => setSucceeded(true))
  };
  const validate = ({place_id, message_type_id, origin_id, recipient_id}) => {
    const errors = {};
    if (!place_id) {
      errors.place_id = 'Veuillez sélectionner un lieu valide.'
    }
    const messageType = messageTypes.find(({id}) => id === message_type_id);
    if (messageType.needOrigin && !origin_id) {
      errors.origin_id = 'Veuillez sélectionner un lieu valide.'
    }
    if (!recipient_id){
      errors.recipient_id = 'Veuillez sélectionner un destinataire valide.'
    }
    return errors;
  };

  return (
    <FormPage title={title}>
      <Formik 
        onSubmit={submit}
        initialValues={formValues}
        validate={validate}
        enableReinitialize
      >
        {({values, touched, errors, handleSubmit}) => {
          const messageType = messageTypes
            .find(mt => mt.id === values.message_type_id)
          return(
            <>
            <EditWarning
              created={values.created}
              onConfirm={handleSubmit} 
              onCancel={toggleWarning}
              open={isOpen}
            />
            <Card title={settingsId ? 'Modification' : 'Nouvel envoi'}>
              <Form>
              <Field
                  component={TextField}
                  name='message_type_id'
                  label='Type de message'
                  type='text'
                  select
                  variant="standard"
                  required
                  fullWidth
                  margin='dense'
                >
                  <MenuItem disabled value=''>Choisissez un message</MenuItem>
                  {messageTypes.map(({id, name}) => (
                  <MenuItem key={id} value={id}>
                    {descriptions[name]}
                  </MenuItem>
                  ))}
                </Field>
                <UserAutocomplete
                  name='recipient_id'
                  label='Destinataire'
                  required
                  error={touched['recipient_id'] && !!errors['recipient_id']}
                  helperText={errors['recipient_id']}
                  showPhone={messageType?.method==='sms'}
                />
                <PlaceAutocomplete
                  name='place_id'
                  label='Lieu concerné'
                  required
                  error={touched['place_id'] && !!errors['place_id']}
                  helperText={errors['place_id']}
                />
                {messageType?.needOrigin &&
                <PlaceAutocomplete
                  name='origin_id'
                  label='Origine des bacs'
                  required
                  error={touched['origin_id'] && !!errors['origin_id']}
                  helperText={errors['origin_id']}
                />}
                <Box>
                  <Field
                    component={Checkbox}
                    type='checkbox'
                    name='deleted'
                  />
                  <label>Effacé</label>
                </Box>
                <SubmitButton {...{submitSucceeded}}/>
              </Form>
            </Card>
            </>
          )
        }}
      </Formik>
      {last_sent && 
        <Box my={4}>
          Dernier message envoyé le {stringifyDateTime(new Date(last_sent))}
        </Box>}
    </FormPage>);
};

const empty = {recipient_id:'', message_type_id:'', place_id: '',
  origin_id: null, deleted: false};

const options = [
  {name: 'stock notification'},
  {name: 'not clean sent'},
  {name: 'sent recap'},
  {name: 'received recap', needOrigin: true},
  {name: 'received recap by models', needOrigin: true},
  {name: 'immobilizations'},
  {name: 'daily recovrables'},
  {name: 'returns recap'},
  {name: 'check pickup loadings'},
  {name: 'check pickup deliveries'},
];
