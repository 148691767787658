import React from 'react';
import { Box } from '@mui/material';

export const highlightMatch = (text, query) => {
  if (!text){ 
    text = '';
  }
  if (query==='') {
    return text;
  }
  const matchRegExp = new RegExp('('+escapeRegex(query)+')', 'i');
  const textArray = text.split(matchRegExp);
  return textArray.map((str, index) => (
    <Box key={index} component='span' 
      color={matchRegExp.test(str) ? '#f00' : 'inherit'}>
      {str}
    </Box>));
};

export const filterAndSort = (items, query, keys) => {
  const regExp = new RegExp(escapeRegex(query), 'i');

  // fitler on all keys
  items = items.filter(item => keys.some(key => regExp.test(item[key])))

  // sort on all keys
  keys.slice().reverse().forEach(key =>
    items = items.sort((a, b) => sortByMatch(a, b, key, regExp)));

  // sort on first key if begining of a world
  const startRegExp = new RegExp('\\b'+escapeRegex(query), 'i');
  items = items.sort((a, b) => sortByMatch(a, b, keys[0], startRegExp));

  return items
};

const sortByMatch = (itemA, itemB, key, matchRegExp) => {
  const valueA = itemA[key];
  const valueB = itemB[key];
  const indexA = valueA ? valueA.search(matchRegExp) : -1;
  const indexB = valueB ? valueB.search(matchRegExp) : -1;
  const signA = indexA !== 0 ? Math.sign(indexA) : 1;
  const signB = indexB !== 0 ? Math.sign(indexB) : 1;
  return ((indexA - indexB) * signA * signB);
};

// eslint-disable-next-line
const escapeRegex = s => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
