import { useState, useEffect } from 'react';
import { makeUrl, useApi } from '../utils';
import { useTour, useUpdateTour } from './services';
import { useUserIs } from '../user/services';
import { usePlaces } from '../place/services';

/** State logic for Tour. */
export default function useTourState(tourId) {
  const isSuperadmin = useUserIs()('superadmin');
  const api = useApi();

  const storeTour = useTour(tourId);
  const updateStoreTour = useUpdateTour();
  const [stateTour, setStateTour] = useState({id: tourId});
  const tour = storeTour || stateTour;
  const setTour = storeTour ? updateStoreTour : setStateTour;

  const storePlaces = usePlaces();
  const [statePlaces, setStatePlaces] = useState([]);
  const places = storeTour ? storePlaces : statePlaces;

  const [drivers, setDrivers] = useState([]);
  const workplace_id = tour.workplace ? tour.workplace.id : undefined;

  useEffect(() => {
    (tourId && !storeTour) && api.get('/tours/'+tourId).then(setStateTour);
  }, [tourId, storeTour]);

  useEffect(() => {
    if (workplace_id) {
      api.get(makeUrl('/users', isSuperadmin ? {workplace_id} : {}))
        .then(users => setDrivers(users.map(user => ({
          id: user.user_id,
          name: user.last ? user.last+' '+user.first : user.email
      }))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id]);

  useEffect(() => {
    if (workplace_id && !storeTour) {
      api.get(makeUrl('/places', {workplace_id})).then(setStatePlaces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id, storeTour]);

  return {tour, setTour, drivers, places};
};
