import { stdDate } from '@clients/shared/src/utils';

export default function cleanObject (obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([k,v]) => v!==undefined && v!==null && v!=="")
      .map(([k,v]) => {
        if (typeof v.getMonth === 'function') { //check if it's a date
          return [k, stdDate(v)]
        } else {
          return [k, v]
        }}))
};
