import React from 'react';
import {HorizontalBar} from 'react-chartjs-2'
import { Card, CardContent } from '@mui/material';
import {eventServices} from '@clients/shared/src/event/event-services'
import 'chartjs-plugin-datalabels';

/** The chart to display user activity (deliveries and returns) */
export const ActivityBarChart = (props) => {
  const destinationsCount = 10;
  const { deliveries, start, end } = props;
  const { countByDestination } = eventServices;
  const deliveriesByDestination = countByDestination(deliveries, start, end)
    .slice(0, destinationsCount);

  const data = {
    labels: deliveriesByDestination
      .map(deliveriesGroup => deliveriesGroup.destination),
    datasets: [ 
      {
        label: 'Bacs livrés',
        data: deliveriesByDestination
          .map(deliveriesGroup => deliveriesGroup.count),
        backgroundColor: 'rgba(1, 206, 129, 0.59)',
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter : (value, context) => (
            context.chart.config.data.labels[context.dataIndex])
        }
      }
    ]
  };
  const options = {
    title: {display: false},
    tooltips: {mode: 'index', intersect: true},
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        ticks: {max: Math.max(...data.datasets[0].data) * 1.2, min: 0}
      }],
      yAxes: [{display: false}]
    }
  };
  
  return (deliveriesByDestination.length > 0 
    ? <Card className={props.className}>
        <CardContent>
          <HorizontalBar 
            data={data} 
            options={options} 
            height={window.innerWidth < 600 ? 300 : 150}
          />
        </CardContent>
      </Card>
    : <React.Fragment/>);
};
