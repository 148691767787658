import React  from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { SubmitButton, FormPage, Card } from '../main';
import PlaceAutocomplete from './PlaceAutocomplete'

/** Page to find and access any place in db. */
export default function PlaceSearch({history}) {
  return(
    <FormPage title='Rechercher un point de livraison'>
      <Card title='Point de livraison'>
        <Formik 
          onSubmit={values => history.push('/places/'+values.id)} 
          initialValues={initialValues}
        >
          {({dirty, setValues}) => (
          <Form>
            <PlaceAutocomplete 
              name='id'
              onChange={(e, values) => setValues(values || initialValues)}
            />
            {fields.map(({name, label}) => 
            <Field
              key={name}
              component={TextField}
              name={name}
              label={label}
              margin='dense'
              variant="standard"
              fullWidth
              disabled
            />)}
            <SubmitButton disabled={!dirty}>
              Accéder
            </SubmitButton>
          </Form>)}
        </Formik>
      </Card>
    </FormPage>);
};

const fields = [{name:'address', label:'Adresse'},
                {name:'postal_code', label:'Code Postal'},
                {name:'city', label:'Ville'},]

const initialValues = { address:'', postal_code:'', city:'', id:'' };
