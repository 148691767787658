import React from 'react';
import Link from '../main/Link';
import { pages } from './pages';
import Button from '../main/Button';

const QR = () =>
  <div style={{textAlign: 'center'}}>
    <p>Sélectionnez la catégorie de QR à imprimer :</p>
    {Object.keys(pages).map((pageKey, index) => 
      <div 
        key={index}
        style={{display: 'flex', justifyContent: 'center', marginBottom: 5}}
      >
        <Button>
          <Link to={pages[pageKey].qrCreatePath}>
            {pages[pageKey].name}
          </Link>
        </Button>
        <br/>
      </div>
    )}
  </div>

export default QR;
