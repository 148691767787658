import React from 'react';
import { Typography, Tooltip as MuiTooltip} from '@mui/material';

export default function Tooltip ({text, value=undefined, children, ...others}) {
  return <MuiTooltip 
    title={<Typography variant='subtitle2' children={text}/>}
    children={value ? <span>{value}</span> : children}
    {...others}
  />;
};

