import React from 'react';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { Page, IconButton } from '../main';
import PlaceContent from './PlaceContent';

/** Use match to display place. */
export default function Place ({match}) {
  const [place] = usePlaceOrState(match.params.place_id, true);
  const { id, name } = place || {};
  return (
    <Page 
      title={name} 
      options={<IconButton type='settings' to={`/places/${id}/edit`} />}
    >
      <PlaceContent place={place}/>
    </Page>
  );
};

