import React from 'react';
import { styled } from '@mui/system';
import { Avatar } from '@mui/material';


/** An important text framed in a squared box. */
const Frame = React.forwardRef(
  function Frame ({children, ...other}, ref) {
    return (
      <StyledAvatar {...other} ref={ref}>
        {children}
      </StyledAvatar>
    );
  }
)
export default Frame;


const StyledAvatar = styled(Avatar, {
  label: 'Frame',
  shouldForwardProp: prop => !['secondary', 'error', 'small'].includes(prop)
})(({ theme, secondary, error, small }) => ({
  marginRight: theme.spacing(2),
  width: 'auto',
  color: theme.palette.text.primary,
  height: 30,
  padding: theme.spacing(2),
  minWidth: 30,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '25%',

  ...(small && {
    height: 20,
    padding: theme.spacing(1),
    minWidth: 20,
  }),

  ...(secondary && {
    backgroundColor: 'transparent',
    border: `solid 2px ${theme.palette.primary.main}`,
  }),

  ...(error && {
    backgroundColor: theme.palette.error.main,
  }),
}));
