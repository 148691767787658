import React, { useEffect, useState } from 'react';
import { useIsLoggedIn } from '../user/services';
import { useEvents, useEventsSyncRequired, useSetEventsIsSyncing, 
  useSetRequireEventsSync, useSyncEvent} from '../event/services';
import { useIsOnline } from './useIsOnline';

/** Empty component used to sync events with the db when device is online. */
export function DataSync () {
  const [copyEventsToSync, setCopyEventsToSync] = useState([]);
  const [syncAttempt, setSyncAttempt] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const isOnline = useIsOnline();
  const syncEvent = useSyncEvent();
  const eventsSyncRequired = useEventsSyncRequired()
  const setRequireEventsSync = useSetRequireEventsSync();
  const setEventsIsSyncing = useSetEventsIsSyncing()
  const unsyncEvents = useEvents().filter(({unsync}) => unsync);
  const shouldSync = 
    (copyEventsToSync.length === 0) && (unsyncEvents.length !== 0)
    && isLoggedIn && isOnline && !isIdle;
  const unsyncUpdates = unsyncEvents.filter(({ bac_updates }) => bac_updates);
  const eventsToSync=(unsyncUpdates.length !== 0) ?unsyncUpdates : unsyncEvents;
  useEffect(() => {
    shouldSync && setCopyEventsToSync(eventsToSync.slice(0, 10));
  }, [shouldSync]);
  useEffect(() => {
    const handleSync = (event, delay, resolve, reject) => setTimeout(async() =>{
      if (await syncEvent(event)){
        setSyncAttempt(0);
        resolve();
      } else {
        reject();
      }
    }, delay)
    if (copyEventsToSync.length > 0){
      const syncs = copyEventsToSync.map((event, i) => new Promise(
        (resolve, reject) => handleSync(event, i*100, resolve, reject)
      ))
      setEventsIsSyncing(true);
      Promise.all(syncs)
        .catch(() => setSyncAttempt(c => c + 1))
        .finally(() => {
          setCopyEventsToSync([]);
          setEventsIsSyncing(false);
        })
    }
  }, [copyEventsToSync])
  useEffect(() => {
    setIsIdle(syncAttempt !== 0)
    const idleTime = idleTimes[syncAttempt] ?? idleTimes[idleTimes.length - 1];
    const timeoutId = setTimeout(() => {
      setIsIdle(false)
    }, idleTime * 60 * 1000);
    return () => clearTimeout(timeoutId);
  }, [syncAttempt]);
  useEffect(() => { 
    if (isOnline || eventsSyncRequired) {
      setSyncAttempt(0);
      setRequireEventsSync(false)
      setIsIdle(false);
    }
  }, [isOnline, eventsSyncRequired]);

  return null;
};

const idleTimes = [0, 1, 2, 5, 10, 20, 60, 6*60, 12*60] // in minutes
