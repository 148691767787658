import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export default function PositionsDoughnut ({data}) {
  return <Doughnut 
    data={{
      labels: data.map(row => dataDisplay[row.key].label), 
      datasets: [{
        data: data.map(row => row.total),
        backgroundColor: data.map(row => dataDisplay[row.key].color),
      }]
    }} 
    options={{
      responsive: true,
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          backgroundColor: '#FFFFFF7F',
          borderRadius: 5,
        },
      },
    }}
  />;
};

const dataDisplay = {
  'stock': {label:'En stock', color:'#26C98C',},
  'stockNotClean': {label:'En stock sale', color:'#52E81C',},
  'clients': {label:'Chez clients', color: '#FDDC0C',}, 
  'lost': {label:'Perdus il y a moins de 6 mois', color: '#DA4167',},
  'broken': {label:'Cassés', color: '#992E99',},
  'bac remisé': { label:'Remisés', color: '#EDEAD0',},
};
