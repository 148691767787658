import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { Grid, Box, IconButton } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { H2, H6 } from './Typography';


/** The top bar of a page containing its title. */
export default function LocationBar ({title, subtitle, options}) {
  const history = useHistory();
  const goBack = () => history.goBack();
  useEffect(() => {
    document.title = title + " - Pandobac";
  }, [title]);

  return (
    <StyledGrid container 
      justifyContent='space-between' 
      alignItems='center'
    >
      <Grid item xs={10} container alignItems='center'>
        <IconButton
          size='small'
          onClick={goBack}
          sx={{margin: 'auto 0', color: 'defaultProps.grey'}}
        >
          <ArrowBackIos/>
        </IconButton>
        <Grid item xs={10} container alignItems='baseline'>
          <Box mr={2}><H2>{title}</H2></Box>
          {subtitle && <H6 className={classes.subtitle}>{subtitle}</H6>}
        </Grid>
      </Grid>
      {options && <Box mx={2} color='text.secondary'>{options}</Box>}
    </StyledGrid>
  );
}


const PREFIX = 'LocationBar';

const classes = {
  subtitle: `${PREFIX}-subtitle`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  zIndex: 1,
  position: 'sticky',
  width: '100%',
  top: 0,
  backgroundColor: '#FFFFFF',
  boxShadow: theme.components.shadows.defaultProps.outer,
  padding: theme.spacing(2, 5),
  minHeight: theme.components.subheader.defaultProps.height.default,
  [theme.breakpoints.down('md')]: {
    minHeight: theme.components.subheader.defaultProps.height.xs,
  },
  [`& .${classes.subtitle}`]: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    }
  }
}));