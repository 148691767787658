import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 960,
      xl: 1280
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Cabin',
    fontSize: 16, // px
    htmlFontSize: 20, // px
    h1: {
      fontSize: '3.5rem',
      fontWeight: 1000 
    },
    h2: {
      fontSize: '1.4rem',
      fontWeight: 1000,
      color: '#4B7E5F', // Dark green
    },
    h5: {
      fontWeight: 1000,
    },
  },
  palette: {
    primary: {
      main: '#FDDC0C', // Yellow
    },
    secondary: {
      main: '#26C98C', // Light Green/Blue
    },
    error: {
      main: '#e23d49',
    },
    text: {
      primary: '#2C2D2E', // Extra dark grey
      secondary: '#4B7E5F', // Dark green
    },
    defaultProps: {
      grey: '#0000008a',
      lightGrey: '#F2F2F2', // Light grey
      white: '#FFFFFF'
    },
    avatar: {
      bg: '#bdbdbd',
      color: '#fafafa',
    }
  },
  spacing: factor => 4 * factor // px
}), { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'] , factor: 4 });

const themeWithProps = {
    shadows: {
      defaultProps: {
        inner: 'inset 2px 0.5px 4px rgba(0, 0, 0, 0.2)',
        outer: '3px 1px 4px rgba(0, 0, 0, 0.2)',
        outerExtra: '0px 0.5px 10px rgba(0, 0, 0, 0.5)'
      },
    },
    header: {
      defaultProps: {
        height: {
          default: theme.spacing(15),
          xs: theme.spacing(12)
        },
        padding: {
          default: theme.spacing(4, 0),
          xs: theme.spacing(3, 0)
        }
      },
    },
    subheader: {
      defaultProps: {
        height: {
          default: theme.spacing(12),
          xs: theme.spacing(10)
        }
      },
    },
    bacCard: {
      defaultProps: {
        height: 92,
        maxWidth: 300,
      },
    }
}

const themeWithOverrides = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        paddingTop: 0,
        paddingBottom: 0,
        '&.Mui-selected': {
          backgroundColor: theme.palette.defaultProps.lightGrey,
          boxShadow: themeWithProps.shadows.defaultProps.inner
        },
        minHeight: 0,
        height: themeWithProps.subheader.defaultProps.height.default,
        [theme.breakpoints.between('xs', 'md')]: {
          height: themeWithProps.subheader.defaultProps.height.xs,
        }
      },
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      a: {
        textDecoration: 'none',
        backgroundColor: 'black'
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(4),
        '&:last-child': {
          paddingBottom: theme.spacing(4)
        }
      }
    }
  },
  MuiGrid: {
    styleOverrides: {
      container: {
        width: 'auto'
      }
    }
  },
  label: {
    styleOverrides: {
      '& + .MuiInput-formControl': {
        marginTop: 0
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginRight: 0
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          margin: 0
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        '&.Mui-expanded': {
          margin: 0
        },
      }
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      edgeEnd: {
        marginRight: -8
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        }
      }
    }
  }
}

const themeComplete = {
  ...theme,
  components: {
    ...themeWithProps,
    ...themeWithOverrides
  },
}
export default themeComplete;
