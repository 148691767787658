import BacQR from './BacQR';
import PlaceQR from './PlaceQR';

export const pages = {
  bacs: { 
    name: 'Bac',
    path: '/bacs/',
    component: BacQR, 
    qrCreatePath: 'qr/bacs'
  },
  places: {
    name: 'Client',
    path: '/places/',
    component: PlaceQR,
    qrCreatePath: 'qr/places' 
  },
}
