import { LOAD_PLACES, ADD_PLACE, SET_PLACE, DELETE_PLACE,
  SET_PLACES_LOAD_REQUIRED, SET_PLACES_IS_LOADING, INITIALIZE_PLACES
} from "../actionTypes";

const initialState = {
  allIds: [],
  byIds: {},
  loadRequired: true,
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_PLACES: {
      const {places} = action.payload;
      return {
        ...state,
        allIds: places.map(place => place.id),
        byIds: places.reduce((obj, place) => {
          obj[place.id] = place;
          return obj
        }, {}),
      };
    }
    case ADD_PLACE: {
      const {id, place} = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: place
        }
      };
    }
    case SET_PLACE: {
      const {place} = action.payload;
      return {
        ...state,
        byIds: { 
          ...state.byIds, 
          [place.id]: place
        }
      };
    }
    case DELETE_PLACE: {
      const { id } = action.payload;
      const { [id]:place, ...othersByIds } = state.byIds;
      return {
        ...state,
        allIds: state.allIds.filter(obj => obj !== id),
        byIds: othersByIds
      };
    }
    case SET_PLACES_LOAD_REQUIRED: {
      const { loadRequired } = action.payload;
      return {
        ...state,
        loadRequired,
      };
    }
    case SET_PLACES_IS_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case INITIALIZE_PLACES: {
      return initialState
    }
    default:
      return state;
  }
};


