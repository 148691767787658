import React from 'react';
import { Grid } from '@mui/material';
import LocationBar from './LocationBar';
import OfflineIndicator from './OfflineIndicator';

/** A basic page. */
export default function Page({children, title, subtitle=null, options}) {
  return (
    <Grid container 
      direction='column'
      wrap='nowrap'
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <Grid item xs='auto'
        component={LocationBar}
        title={title}
        subtitle={subtitle}
        options={options}
      />
      <OfflineIndicator />
      { children }
  </Grid>
  );
}
