import React from 'react';
import { Card, CardContent } from '@mui/material';
import qs from 'query-string';
import { useApi } from '@clients/shared/src/utils';
import { FormPage } from '../main';
import Universal from '../scan/Universal';

/** The form to add ownership of a bac to a place. */
export default function AddBac({match, location}) {
  const api = useApi();
  const { place_id } = match.params;
  const { name } = qs.parse(location.search);
  const handleResult = bac_shown_id => (
    api.put('/bacs/' + bac_shown_id + '/owner', {place_id}));
  return (
    <FormPage title={`Bacs appartenant à ${name || place_id}`}>
      <Card>
        <CardContent>
          <Universal handleResult={handleResult} />
        </CardContent>
      </Card>
    </FormPage>)
};
