import { 
  SET_CURRENT_USER, 
  ADD_EVENT, DELETE_EVENT, UPDATE_EVENT, REPLACE_EVENT, LOAD_EVENTS,
  SET_EVENTS_SYNC_REQUIRED, SET_EVENTS_IS_SYNCING, 
  ADD_PLACE, LOAD_PLACES, SET_PLACE, DELETE_PLACE, SET_PLACES_LOAD_REQUIRED,
  SET_PLACES_IS_LOADING, INITIALIZE_PLACES, 
  UPDATE_TOUR, LOAD_TOURS, ADD_TOUR, SET_TOURS_LOAD_REQUIRED, INITIALIZE_TOURS,
  SET_TOURS_IS_LOADING,
  SET_ERROR_MESSAGE, REMOVE_DESTINATION, SET_DESTINATION, SET_BAC_EMPTY,
  SET_BAC_CLEAN
} from './actionTypes';

//Curent user actions
export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: {
    user
  }
})

//Events actions
export const addEvent = event => ({
  type: ADD_EVENT,
  payload: {
    id: event.id,
    event
  }
})

export const deleteEvent = id => ({
  type: DELETE_EVENT,
  payload: {
    id
  }
})

export const updateEvent = (id, key, value) => ({
  type: UPDATE_EVENT,
  payload: {
    id, key, value
  }
})

export const replaceEvent = (prevId, newEvent) => ({
  type: REPLACE_EVENT,
  payload: {
    prevId,
    newEvent,
  }
})

export const loadEvents = events => ({
  type: LOAD_EVENTS,
  payload: {
    events,
    loadDate: (new Date()).toISOString(),
  }
})

export const setEventsSyncRequired = syncRequired => ({
  type: SET_EVENTS_SYNC_REQUIRED,
  payload: {
    syncRequired
  }
})

export const setEventsIsSyncing = isSyncing => {
  return({
  type: SET_EVENTS_IS_SYNCING,
  payload: {
    isSyncing
  }
})}

//Places actions
export const addPlace = place => ({
  type: ADD_PLACE,
  payload: {
    id: place.id,
    place
  }
})

export const loadPlaces = places => ({
  type: LOAD_PLACES,
  payload: {
    places
  }
})

export const setPlace = place => ({
  type: SET_PLACE,
  payload: {
    place
  }
})

export const deletePlace = id => ({
  type: DELETE_PLACE,
  payload: {
    id
  }
})

export const setPlacesLoadRequired = loadRequired => ({
  type: SET_PLACES_LOAD_REQUIRED,
  payload: {
    loadRequired
  }
})

export const setPlacesIsLoading = isLoading => ({
  type: SET_PLACES_IS_LOADING,
  payload: {
    isLoading
  }
})

export const initializePlaces = () => ({
  type: INITIALIZE_PLACES,
})


//ScanParams actions
export const removeDestination = () => ({
  type: REMOVE_DESTINATION,
})

export const setDestination = destination => ({
  type: SET_DESTINATION,
  payload: {
    destination
  }
})

export const setBacEmpty = bac_empty => ({
  type: SET_BAC_EMPTY,
  payload: {
    bac_empty
  }
})

export const setBacClean = bac_clean => ({
  type: SET_BAC_CLEAN,
  payload: {
    bac_clean
  }
})


//Tours actions
export const updateTour = (tour) => ({
  type: UPDATE_TOUR,
  payload: {
    tour
  }
})

export const loadTours = tours => ({
  type: LOAD_TOURS,
  payload: {
    tours
  }
})

export const addTour = tour => ({
  type: ADD_TOUR,
  payload: {
    tour
  }
})

export const setToursLoadRequired = loadRequired => ({
  type: SET_TOURS_LOAD_REQUIRED,
  payload: {
    loadRequired
  }
})

export const setToursIsLoading = isLoading => ({
  type: SET_TOURS_IS_LOADING,
  payload: {
    isLoading
  }
})

export const initializeTours = () => ({
  type: INITIALIZE_TOURS,
})

//Utils actions
export const setErrorMsg = message => ({
  type: SET_ERROR_MESSAGE,
  payload: {
    message
  }
})
