import React from 'react'
import { List, ListItem, ListItemText, ListItemIcon, ListItemButton
  } from '@mui/material';
import { Send } from '@mui/icons-material';
import { prettyDate } from '@clients/shared/src/utils';
import { Link, Button, Card } from '../main';
import descriptions from '../messages/descriptions'


/** List of user's messages' settings. */
export default function MessagesSettings ({messagesSettings, user}) {
  const { user_id, workplace_id } = user;
  const messageTypesSet =  
    [...(new Set(messagesSettings.map(ms => ms.message_type)))];

  return(
    <Card
      alignItems='center'
      justifyContent='center'
      title='Réglages des messages périodiques'
      mt={2}
    >
      <List>
      {messageTypesSet.map( name  => {
        return (
        <React.Fragment key={name}>
          <ListItem>
            <ListItemIcon><Send/></ListItemIcon>
            <ListItemText primary={descriptions[name]} />
          </ListItem>
          {messagesSettings.filter(ms => ms.message_type === name).map(ms =>
            <ListItemButton key={ms.id} dense component={Link}
              to={'/messages-settings/' + ms.id}
            >
              <ListItemText inset>
                <PrettySettings {...{...ms}} />
              </ListItemText>
            </ListItemButton>
          )}
        </React.Fragment>
      )})}
        <ListItem alignItems='center'>
          <Button secondary fullWidth component={Link}
            to={{
              pathname:'/messages-settings/create',
              state:{recipient_id: user_id, place_id: workplace_id},
            }}
          >
            Ajouter un message
          </Button>
        </ListItem>
      </List>
    </Card>
  );
};


const PrettySettings = ({place, deleted, last_sent, message_type}) => {
  let text = (message_type === 'sent recap') ?  'de ' : 'pour ';
  text += place;
  text += last_sent ? ' (' + prettyDate(new Date(last_sent)) + ')' : '';
  return deleted ? <del>{text}</del> : <>{text}</>;
};
