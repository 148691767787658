import { LOAD_TOURS, UPDATE_TOUR, ADD_TOUR, SET_TOURS_LOAD_REQUIRED,
  SET_TOURS_IS_LOADING, INITIALIZE_TOURS } from "../actionTypes";

const initialState = {
  allIds: [],
  byIds: {},
  loadRequired: true,
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_TOURS: {
      const {tours} = action.payload;
      return {
        ...state,
        allIds: tours.map(tour => tour.id),
        byIds: tours.reduce((obj, tour) => {
          obj[tour.id] = tour;
          return obj;
        }, {}),
      };
    }
    case ADD_TOUR: {
      const {tour} = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, tour.id],
        byIds: { 
          ...state.byIds, 
          [tour.id]: tour
        }
      };
    }
    case UPDATE_TOUR: {
      const {tour} = action.payload;
      return {
        ...state,
        byIds: { 
          ...state.byIds,
          [tour.id]: tour
        }
      };
    }
    case SET_TOURS_LOAD_REQUIRED: {
      const { loadRequired } = action.payload;
      return {
        ...state,
        loadRequired,
      };
    }
    case SET_TOURS_IS_LOADING: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case INITIALIZE_TOURS: {
      return initialState
    }
    default:
      return state;
  }
};