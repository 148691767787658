import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { Grid } from '@mui/material';
import store from '@clients/shared/src/redux/store';
import { DataLoader, DataSync } from '@clients/shared/src/utils';
import { Menu, Home, Help, PrivatePages, ErrorBanner, Header } from './main';
import Login from './user/Login';
import SendRecover from './user/SendRecover';
import Recover from './user/Recover';
import ErrorBoundary from './_utils/ErrorBoundary';
import KeyboardListener from './scan/KeyboardListener';
import './App.css';


/** The App. */
export default function App () {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(showMenu => !showMenu);
  //<Route component={KeyboardListener} />
  return (
    <Provider store={store}>
      <StyledGrid container>
        <Menu isOpen={showMenu} toggle={toggleMenu} />
        <Grid container className={classes.fitScreen} direction='column'>
          <Header toggleMenu={toggleMenu}/>
          <Grid item container className={classes.content}>
            <ErrorBoundary>
              <ErrorBanner />
              <DataLoader />
              <DataSync />
              <KeyboardListener />
              <Switch>
                <Route path="/" exact component={ (props) => 
                    <Home {...props}/>} />
                <Route path="/help" component={ (props) => 
                    <Help {...props}/>} />
                <Route path="/users/login" render={ (props) => 
                    <Login {...props}/>} />
                <Route path="/users/send-recover" render={ (props) => 
                    <SendRecover {...props}/>} />
                <Route path="/users/recover/:token" render={(props) => 
                    <Recover {...props}/>} />
                <PrivatePages />
              </Switch>
            </ErrorBoundary>
          </Grid>
        </Grid>
      </StyledGrid>
    </Provider>
  );
};


const PREFIX = 'App';

const classes = {
  fitScreen: `${PREFIX}-fitScreen`,
  content: `${PREFIX}-content`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.fitScreen}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${theme.spacing(50)})`,
      marginLeft: theme.spacing(50),
    },
  },
  [`& .${classes.content}`]: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    top: theme.components.header.defaultProps.height.default,
    width: '100%',
    [theme.breakpoints.between('xs', 'md')]: {
      top:  theme.components.header.defaultProps.height.xs,
    },
  }
}))
