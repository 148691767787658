import React from 'react';
import { Grid } from '@mui/material';
import Page from './Page';


/** A page for forms. */
export default function FormPage({children, title, subtitle, ...otherProps}) {
  return (
    <Page title={title} subtitle={subtitle} {...otherProps} >
      <Grid item container 
        direction='column'
        sx={theme => ({
          mx: 'auto', 
          mt: theme.components.subheader.defaultProps.height.default,
          [theme.breakpoints.down('md')]: {
            mt: 4,
          },
        })}
      >
        { children }
      </Grid>
    </Page>
  );
}

