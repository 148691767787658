import React, { useState, useEffect } from 'react';
import { fr } from 'date-fns/locale'
import { endOfMinute, parseISO, startOfMinute } from 'date-fns';
import { Grid, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Field, useField } from 'formik';
import { MobileDateTimePicker } from 'formik-mui-lab';

export default function DateTimePickerComplete ({
  xs, label, required, error, ...props
}){
  const [ { value } ,,{ setValue } ] = useField(props.name);
  const [selectedDate, setSelectedDate]=useState(value ? parseISO(value) : null)
  const adaptTime = props.name === 'before' ? endOfMinute : startOfMinute;
  useEffect(() => {
    setSelectedDate(value ? parseISO(value) : null)
  }, [value]);

  return (
    <Grid item xs={xs}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
        <Field
          component={MobileDateTimePicker}
          inputFormat='d MMM yyyy à HH:mm'
          toolbarFormat='d MMM yyyy à HH:mm'
          toolbarTitle=''
          disableMaskedInput
          renderInput={params => (
            <TextField
              fullWidth
              variant='standard'
              {...{...params, label, required, error}}
            />
          )}
          okText=''
          cancelText=''
          DialogProps={{sx:dialogStyles}}
          allowSameDateSelection
          value={selectedDate}
          onDateChange={setSelectedDate}
          onAccept={() => setValue(selectedDate
            ? adaptTime(selectedDate).toISOString()
            : ''
          )}
          {...props}
        />
      </LocalizationProvider>
    </Grid>
  )
};

const dialogStyles = {
  '& .PrivatePickersToolbar-root':{
    backgroundColor: 'primary.main',
    py: 6,
    px: 3
  },
  '& .PrivateDateTimePickerToolbar-penIcon':{
    display: 'none'
  },
  '& .MuiTypography-h3':{
    display: 'none'
  }
}
