import React from 'react';
import { Link } from '@mui/material';

/** A link which adds styles to standard <a> element. */
export default function ExternalLink ({ className, href, children, color }) {
  return ( 
    <Link
      href={href}
      underline='none'
      color={color || 'inherit'}
      className={`${className || ""}`}
    >
      {children}
    </Link>);
};
