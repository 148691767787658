import React, { useEffect } from 'react';
import { useIsLoggedIn, useAutoupdateUser } from '../user/services';
import { useLoadPlaces, useGetPlacesLoadRequired, useInitializePlaces,
  useAddPlaceRefs } from '../place/services';
import { useAutoupdateEvents } from '../event/services';
import { useLoadTours, useInitializeTours, useGetToursLoadRequired
  } from '../tour/services';


/** Empty component used to load data when used logs in. */
export function DataLoader () {
  const isLoggedIn = useIsLoggedIn();
  const placesLoadRequired = useGetPlacesLoadRequired();
  const [loadPlaces, isLoadingPlaces] = useLoadPlaces();
  const [addPlaceRefs, refsIsLoading] = useAddPlaceRefs();
  const toursLoadRequired = useGetToursLoadRequired();
  const [loadTours, isLoadingTours] = useLoadTours();
  const initializePlaces = useInitializePlaces();
  const initializeTours = useInitializeTours();

  // Places data
  useEffect(() => {
    isLoggedIn
      ? placesLoadRequired && !isLoadingPlaces && loadPlaces()
      : initializePlaces();
    }, [isLoggedIn, placesLoadRequired, isLoadingPlaces]);

  // Adding place refs
  useEffect(() => {
    isLoggedIn && !placesLoadRequired && !isLoadingPlaces && addPlaceRefs();
  }, [isLoggedIn, placesLoadRequired, isLoadingPlaces]);

  // Tours data
  useEffect(() => { 
    isLoggedIn 
      ? toursLoadRequired && !isLoadingTours && loadTours() 
      : initializeTours();
  }, [isLoggedIn, toursLoadRequired, isLoadingTours]);
  
  // Events data
  useAutoupdateEvents();

  // Curent user data
  useAutoupdateUser();

  return null;
};
