import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import { Box } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { FormPage, SubmitButton, Card } from '../main';


/** The form to create or edit a specific role. */
export default function Role ({match, history}) {
  const api = useApi();
  const [ role, setRole ] = useState({
    "id": match.params.role_id,
    "name": '',
    "label": '',
    "description": '',
    "deleted": false,
  });
  const [ submitSucceeded, setSucceeded ] = useState(false);
  useEffect(() => {
    role.id && api.get().then(setRole);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role.id]);
  const submitNew = values => api.post('/roles', values)
    .then(() => history.replace('/roles'))
  const submitUpdate = values => api.put(null, values)
    .then(setRole)
    .then(() => setSucceeded(true))

  return (
    <FormPage title={`${role.id ? 'Modification' : 'Création'} d'un rôle`}>
      <Formik
        onSubmit={role.id ? submitUpdate : submitNew}
        initialValues={role}
        enableReinitialize
      >
        <Card title={role.id ? 'Modification' : 'Nouveau rôle'}>
          <Form>
            {fields.map(field =>
            <Field
              key={field.name}
              component={TextField}
              variant="standard"
              fullWidth
              margin='dense'
              {...{...field}}
            />)}
            <Box>
              <Field
                component={Checkbox}
                type='checkbox'
                name='deleted'
              />
              <label>Effacé</label>
            </Box>
            <SubmitButton {...{submitSucceeded}}/>
          </Form>
        </Card>
      </Formik>
    </FormPage>
  );
};

const fields = [
  {name:'name', label:'Nom dans le code', required:true},
  {name:'label', label:'Nom affiché'},
  {name:'description', label:'Description'},
]
