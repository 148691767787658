const messageTypeDescriptions = {
  'to receive recap': 'Bacs non rendus - quotidien',
  'received recap': 'Bilan des bacs reçus - hebdomadaire',
  'received recap by models': 'Bilan des modèles reçus - hebdomadaire',
  'sent recap': 'Bilan des envois - quotidien',
  'kpi recap': 'KPI - mensuel',
  'daily recovrables': 'Bacs à récupérer - SMS quoditien',
  'stock notification': 'Alerte stock',
  'immobilizations': 'Immobilisations aux PDL - mensuel',
  'not clean sent': 'Utilisation de bacs sales',
  'returns recap': 'Bilan des retours par PDL - hebdomadaire',
  'check pickup loadings': 'Verif collecte du jour',
  'check pickup deliveries': 'Verif livraison du jour',
};

export default messageTypeDescriptions;
