import { REMOVE_DESTINATION, SET_DESTINATION, SET_BAC_EMPTY, SET_BAC_CLEAN
  } from "../actionTypes";

const initialState = {
  bac_empty: null,
  bac_clean: null,
  destination: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_BAC_EMPTY: {
      const { bac_empty } = action.payload;
      return {
        ...state,
        bac_empty
      };
    }
    case SET_BAC_CLEAN: {
      const { bac_clean } = action.payload;
      return {
        ...state,
        bac_clean
      };
    }
    case SET_DESTINATION: {
      const { destination } = action.payload;
      return {
        ...state,
        destination
      };
    }
    case REMOVE_DESTINATION: {
      return {
        ...state,
        destination: {}
      }
    }
    default:
      return state;
  }
};


