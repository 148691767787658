import React from 'react';
import { TableContainer, Paper, Table, TableBody, TableRow, TableHead,
    TableCell, } from '@mui/material';


export default function LostTable({header, body}) {

  const total = body.reduce((tot, place) => tot + place.lost_cnt, 0);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((cell, key) => 
            <TableCell key={key}>{cell.label}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map(place => (
          <TableRow hover key={place.id}>
            <TableCell><strong>{place.name}</strong></TableCell>
            <TableCell>{place.lost_cnt}</TableCell>
          </TableRow>
          ))}
          <TableRow hover sx={{backgroundColor: 'background.default'}}>
            <TableCell><strong>Total</strong></TableCell>
            <TableCell><strong>{total}</strong></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
 
