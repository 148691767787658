import { useDispatch, useSelector } from 'react-redux';
import { updateTour, loadTours, addTour, setToursLoadRequired,setToursIsLoading,
  initializeTours } from '../redux/actions';
import { getTours, getTour, getTourPlaces, getToursLoadRequired,
  getToursIsLoading } from '../redux/selectors';
import { useApi } from '../utils';

/** Load tours in the store. */
export function useLoadTours() {
  const dispatch = useDispatch();
  const isLoading = useToursIsLoading();
  const api = useApi();
  return [() => {
    dispatch(setToursIsLoading(true));
    dispatch(setToursLoadRequired(false));
    api.get('/tours')
      .then(tours => dispatch(loadTours(tours)))
      .finally(() => dispatch(setToursIsLoading(false)));
  }
  , isLoading];
};

/** Initialize tours in store hook */
export function useInitializeTours() {
  const dispatch = useDispatch();
  return () => dispatch(initializeTours());
}

/** Get a tour from store. */
export function useTour(tourId) {
  return useSelector(state => getTour(state, tourId));
};

/** Get all tours from the store. */
export function useTours() {
  return useSelector(state => getTours(state))
    .sort((a, b) => a.name.localeCompare(b.name));
};

/** Update a tour in the store. */
export function useUpdateTour () {
  const dispatch = useDispatch();
  return (tour => dispatch(updateTour(tour)));
};

/** Get places with data from a tour. */
export function useTourPlaces(tourId) {
  return useSelector(state => getTourPlaces(state, tourId));
}

/** Add a single tour to the store. */
export function useAddTour(tour) {
  const dispatch = useDispatch();
  return (tour => dispatch(addTour(tour)));
}

/** Set load of tours is required on 'true' in store hook. */
export function useRequireToursLoad() {
  const dispatch = useDispatch();
  return () => dispatch(setToursLoadRequired(true));
}

/** Get if load of tours is required from store hook. */
export function useGetToursLoadRequired() {
  return useSelector(state => getToursLoadRequired(state));
}

/** Get if load of tours is pending from store hook. */
export function useToursIsLoading() {
  return useSelector(state => getToursIsLoading(state));
}
