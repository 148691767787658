import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useIsLoading, useIsLoggedIn,
  } from '@clients/shared/src/user/services';

/** Gives access to page only to connected user (otherwiser redirect them). */
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoading = useIsLoading();
  const isLoggedIn = useIsLoggedIn(); 
  return (isLoading ? null : 
    <Route 
      {...rest} 
      render={props => isLoggedIn ? <Component {...props} />
        : <Redirect 
            to={{ 
              pathname: '/users/login', 
              state: {from: props.location} 
            }}
          />
        } 
    />);
};

