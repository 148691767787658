import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Grid } from '@mui/material';
import { useApi, isEmail } from '@clients/shared/src/utils';
import { useUpdateUser } from '@clients/shared/src/user/services'
import { FormPage, Link, Button, SubmitButton, Card } from '../main';
import { Load } from '../_utils';

/** The current user profile page. */
export default function Profile () {
  const { isLoading, get, put } = useApi();
  const [ user, setUser ] = useState({first:'', last:'', email:''});
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const updateUser = useUpdateUser();
  useEffect(() => {get().then(setUser);}, [get]);
  const submit = values => put(undefined, values).then(() => {
    setUser(values);
    updateUser();
    setSucceeded(true);
  }, e=>({e}));
  const name = [user.first, user.last].filter(x => x).join(" ") || user.email;
  return (
  <FormPage title={name} subtitle={user.workplace}>
    <Load isLoading={isLoading}>
      <Card title='Informations personnelles'>
        <Formik onSubmit={submit} initialValues={user} enableReinitialize>
          <Grid container direction='column' component={Form}>
            {[{name: 'first', label: 'Prénom',},
              {name: 'last', label: 'Nom',},
              {name: 'email', label: 'Email',}].map(({name, label}) => (
            <Field 
              key={name}
              component={TextField}
              name={name}
              label={label}
              required
              margin='dense'
              variant="standard"
              type={name==='email' ? 'email' : 'text'}
              validate={name==='email' ? isEmail : null}
              />))}
            <SubmitButton {...{submitSucceeded}}/>
          </Grid>
        </Formik>
        <Button secondary fullWidth component={Link} 
          to={'/users/'+user.user_id+'/password'}
        >
          Changer mon mot de passe
        </Button>
      </Card>
      <Card mt={1}>
        <Button secondary fullWidth mt={0} component={Link} to='/users/login'>
          Déconnexion
        </Button>
      </Card>
    </Load>
  </FormPage>);
};
