import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { Paper, TableContainer, Table, TableBody, TableRow, TableHead, 
  TableCell, Box, CircularProgress } from '@mui/material';
import { teal, lime, lightBlue, amber, cyan, red } from '@mui/material/colors';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useApi, stdDate, makeUrl } from '@clients/shared/src/utils';
import { usePlace } from '@clients/shared/src/place/services';
import { Page, Tooltip } from '../main';
import { SearchBar, useSearch} from '../searchbar';


/** Billing data table. */
export default function Billing({match, location}) {
  const { place_id } = match.params;
  const { name } = usePlace(place_id) || {};
  const apiLeases = useApi({disableConcurrentRequest: true});
  const apiEvents = useApi({disableConcurrentRequest: true});
  const apiTracking = useApi({disableConcurrentRequest: true});
  const apiDeliveriesImmo = useApi({disableConcurrentRequest: true});
  const apiCaretakerImmo = useApi({disableConcurrentRequest: true});
  const search = qs.parse(location.search);
  const [sent, setSent] = useState([]);
  const [allLeases, setLeases] = useState([]);
  const [tracking, setTracking] = useState({});
  const [deliveriesImmo, setDeliveriesImmo] = useState({});
  const [caretakerImmo, setCaretakerImmo] = useState({});
  useEffect(
    () => {apiLeases.get(`/places/${place_id}/leases`)
      .then(setLeases)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [place_id]);
  const loadEventsAndTrackings = () => {
    apiEvents.get(makeUrl('/events', {...search, destination_id: place_id}))
      .then(setSent)
      .catch(()=>{});
    apiTracking.get()
      .then(setTracking)
      .catch(()=>{});
  }
  const loadImmos = () => {
    (allLeases.length > 0) && apiDeliveriesImmo.get(
      makeUrl(`/places/${place_id}/immobilizations-deliveries`,search))
      .then(setDeliveriesImmo)
      .catch(()=>{});
    (allLeases.length > 0) && apiCaretakerImmo.get(
      makeUrl(`/places/${place_id}/immobilizations-caretaker`, search))
      .then(setCaretakerImmo)
      .catch(()=>{});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadEventsAndTrackings, [location.search, place_id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadImmos, [location.search, allLeases.length]);
  
  const filters = [
    {template: 'after',
      initialValue: stdDate(startOfMonth(subMonths(new Date(), 1))),
      filterFn: (after, lease) => lease.end >= after},
    {template: 'before',
      initialValue: stdDate(endOfMonth(subMonths(new Date(), 1))),
      filterFn: (before, lease) => lease.end <= before},
    {name: 'days30', label: 'immo 30 jours', type: 'switch'},
  ];
  const [searchBarProps, leases] = useSearch(filters, allLeases);

  const leasesByModel = Object.values(
    leases.reduce(
      (byModel, lease) => {
        byModel[lease.model] = byModel[lease.model] || [];
        byModel[lease.model].push(lease);
        return byModel;
      }
    , {})
  )
  const models = [...new Set(sent.map(e => e.bac_model))];
  const sents_per_models = tracking.sents_models || [];
  const sentsTotal = sents_per_models.reduce((s, m)=> s + m[1], 0)
  const new_cnt = tracking.bacs_cnt - tracking.same_cnt || 0;
  const tracking_cnt = tracking.before_bacs_cnt + new_cnt || 0;
  const totalDeliveriesImmo = Object.values(deliveriesImmo)
    .reduce((sum, days) => sum + days, 0)
  const totalCaretakerImmo = Object.values(caretakerImmo)
    .reduce((sum, days) => sum + days, 0)
  
  const trackingRows = [
    {name: 'Bacs suivi en début de période', key: 'before_bacs_cnt'},
    {name: 'Bacs ajoutés', key: 'new_cnt', value: new_cnt},
    {name: 'Bacs retrouvés', key: 'found_cnt'},
    {name: 'Déclarés perdus après réception', key: 'received_lost_cnt'},
    {name: 'Déclarés perdus après envoi', key: 'sent_lost_cnt'},
    {name: 'Bacs actifs', key: 'bacs_cnt'},
    {name: 'Bacs vierges', key: 'virgins'},
  ];

  return (
    <Page title={'Facturation ' + name}>
    <SearchBar {...searchBarProps}/>
    <Box width='0.9' mx='auto' my={8}>
      <TableContainer component={Paper} >
        <Table>
          <TableHead>
            <TableRow sx={{bgcolor: theme => theme.palette.grey[100]}}>
              <TableCell>Catégorie</TableCell>
              <TableCell>Détail</TableCell>
              <TableCell>Nombre</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow sx={{bgcolor: teal[100]}}>
              <TableCell><strong>Lavage</strong></TableCell>
              <TableCell>Bacs envoyés par Pandobac</TableCell>
              <TableCell>
                {apiEvents.isLoading ? <Spinner/> : sent.length}
              </TableCell>
            </TableRow>
            { !apiEvents.isLoading && models.map(model => 
            <TableRow key={model} sx={{bgcolor: teal[50]}}>
              <TableCell>Lavage détail</TableCell>
              <TableCell>{model || 'Bac sans modèle'}</TableCell>
              <TableCell>
              {sent.filter(e => e.bac_model===model).length}  
              </TableCell>
            </TableRow>
            )}

            <TableRow sx={{bgcolor: amber[100]}}>
              <TableCell><strong>Contrats location</strong></TableCell>
              <TableCell>Total bacs nouveaux contrats</TableCell>
              <TableCell>
                {apiLeases.isLoading 
                  ? <Spinner/> 
                  : leases.reduce((total, l) => total + l.bac_count, 0)}
              </TableCell> 
            </TableRow>
            { !apiLeases.isLoading && 
            leasesByModel.map(leases => 
            <TableRow key={leases[0].model_id} sx={{bgcolor: amber[50]}}>
              <TableCell>Contrats détail</TableCell>
              <TableCell>{leases[0].model}</TableCell>
              <TableCell>
                {leases.reduce((total, l) => total + l.bac_count, 0)}
              </TableCell>
            </TableRow>
            )}
            <TableRow sx={{bgcolor: amber[50]}}>
              <TableCell>Contrats détail</TableCell>
              <TableCell>Nb contrats</TableCell>
              <TableCell>
                {leases.length}
              </TableCell>
            </TableRow>

            <TableRow sx={{bgcolor: lightBlue[100]}}>
              <TableCell><strong>Suivi</strong></TableCell>
              <TableCell>Bacs suivi : anciens et nouveaux</TableCell>
              <TableCell>
                {apiTracking.isLoading ? <Spinner/> : tracking_cnt}
              </TableCell>
            </TableRow>
            {!apiTracking.isLoading && trackingRows.map(({name, key, value}) => 
            <TableRow key={key} sx={{bgcolor: lightBlue[50]}}>
              <TableCell>Suivi détail</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{value ?? tracking[key]}</TableCell>
            </TableRow>
            )}

            <TableRow sx={{bgcolor: red[100]}}>
              <TableCell>
                <strong>Rotations</strong>
                <Tooltip text={TooltipTitle} sx={{ml:1}}>
                  <AnnouncementIcon color='warning' />
                </Tooltip>
              </TableCell>
              <TableCell>Modèles de bac</TableCell>
              <TableCell>
                {apiTracking.isLoading ? <Spinner/> : sentsTotal}
              </TableCell>
            </TableRow>
            {!apiTracking.isLoading 
            && sents_per_models.map(([model, sent_count]) => 
            <TableRow key={model} sx={{bgcolor: red[50]}}>
              <TableCell>Rotations par modèle</TableCell>
              <TableCell>{model}</TableCell>
              <TableCell>{sent_count}</TableCell>
            </TableRow>
            )}

            {(allLeases.length > 0) && (
              <TableRow sx={{bgcolor: lime[100]}}>
                <TableCell >
                  <strong>
                    Immobilisations aux points de livraison sup. à 15 jours
                  </strong>
                </TableCell>
                <TableCell>Modèles de bac</TableCell>
                <TableCell>
                  {apiDeliveriesImmo.isLoading 
                    ? <Spinner/> : totalDeliveriesImmo}
                </TableCell>
              </TableRow>
            )}
            {(allLeases.length > 0) && !apiDeliveriesImmo.isLoading &&
            Object.entries(deliveriesImmo).map(([model, days]) =>
            <TableRow key={model} sx={{bgcolor: lime[50]}}>
              <TableCell>Immo en jours par modèle</TableCell>
              <TableCell>{model}</TableCell>
              <TableCell>{days}</TableCell>
            </TableRow>
            )}

            {(allLeases.length > 0) && (
              <TableRow sx={{bgcolor: cyan[100]}}>
                <TableCell >
                  <strong>
                    Immobilisations chez notre client sup. à 15 jours
                  </strong>
                </TableCell>
                <TableCell>Modèles de bac</TableCell>
                <TableCell>
                  {apiCaretakerImmo.isLoading 
                    ? <Spinner/> : totalCaretakerImmo}
                </TableCell>
              </TableRow>
            )}
            {(allLeases.length > 0) && !apiCaretakerImmo.isLoading &&
            Object.entries(caretakerImmo).map(([model, days]) =>
            <TableRow key={model} sx={{bgcolor: cyan[50]}}>
              <TableCell>Immo en jours par modèle</TableCell>
              <TableCell>{model}</TableCell>
              <TableCell>{days}</TableCell>
            </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </Page>);
};

const Spinner = () => <CircularProgress color='secondary' size={20}/>

const TooltipTitle = `Le nombre d'envoi est aussi disponibles sur la page 
Historique accessible au client, vérifiez leur cohérence.`
// Les pages Billing et History n'utilisent pas la même requête.

// TODO: Les .catch()  des requêtes, ne sont là que pour éviter l'affichage d'une erreur
// lorsqu'une requete en cours est annulée par une nouvelle. Le soucis c'est 
// que si un autre type d'erreur survient, il ne sera pas affiché non plus. 
// => Trouver une solution pour "filter" les erreurs, idéalement une solution générique
// utilisable dans tous les fichiers qui permettent l'annulation de requetes
