import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Checkbox } from 'formik-mui';
import qs from 'query-string';
import { Box, Grid, MenuItem, FormControl } from '@mui/material';
import { useApi, makeUrl } from '@clients/shared/src/utils';
import { useWorkplace } from "@clients/shared/src/place/services";
import { useUserIs } from '@clients/shared/src/user/services';
import { FormPage, SubmitButton, DatePicker, Card, Button } from '../main';

/** The form to edit a specific lease. */
export default function Lease ({ route, match, history, location }) {
  const leaseId = match.params.lease_id;
  const search = qs.parse(location.search);
  const empty = {supplier_id:'', client_id:'', model_id:'', bac_count:'',
    note:'', deleted:false, end:null, validated:null};
  const [ lease, setLease ] = useState({...empty, ...search});
  const [ suppliers, setSuppliers ] = useState(
    search.supplier_id ? [{id: search.supplier_id, name:''}] : []);
  const [ clients, setClients ] = useState(
    search.client_id ? [{id: search.client_id, name:''}] : []);
  const [ models, setModels ] = useState(
    search.model_id ? [{id: search.model_id, name:''}] : []);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const api = useApi();
  const userIs = useUserIs()
  const isLogistics = userIs('logistics')
  const isLeaser = userIs('leaser')
  const workplace = useWorkplace()

  useEffect(() => {leaseId && api.get().then(lease => {
      setLease(lease)
      clients.length===0 && setClients([{id: lease.client_id, name:''}]);
      models.length===0 && setModels([{id: lease.model_id, name:''}]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseId]);
  useEffect(() => {
    isLogistics && api.get(makeUrl('/leases', {workplace_id: workplace.id}))
      .then(leases => {
        setSuppliers(uniqueById(
          leases.map(l => ({id: l.supplier_id, name: l.supplier}))))
        setModels(ms => uniqueById(
            [...ms, ...leases.map(l => ({id: l.model_id, name: l.model}))])
           .sort((a, b) => a.name.localeCompare(b.name)))
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isLogistics, workplace.id])
  useEffect(() => {
    if (isLogistics && lease.supplier_id==='' && suppliers.length===1) {
      setLease(l => ({...l, supplier_id: suppliers.at(0).id}))
  }}, [isLogistics, lease.supplier_id, suppliers])
  useEffect(() => {isLeaser &&
    api.get(makeUrl('/places', {workplace_id: workplace.id})).then(setClients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeaser])
  useEffect(() => {isLeaser && api.get(makeUrl('/models', {workplace_id: workplace.id}))
    .then(data => setModels(ms => uniqueById([...ms, ...data])
                                 .sort((a, b) => a.name.localeCompare(b.name))))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeaser])
  useEffect(() => {
    if (!isLeaser && workplace.id && lease.client_id==='') {
      setLease(l => ({...l, client_id: workplace.id}))
  }}, [isLeaser, lease.client_id, workplace.id])
  
  let title = 'Création d\'un contrat';
  let formTitle = 'Nouveau contrat';
  if (leaseId) {
    title = 'Contrat ' + lease.client + ' (' + lease.model + ')';
    formTitle = 'Modification';
  };
  const submitNew = values => api.post('/leases', values)
    .then(() => history.replace('/leases'));
  const submitUpdate = values => api.put(undefined, values)
    .then(setLease)
    .then(() => setSucceeded(true), () => setSucceeded(false));
  const submit = leaseId ? submitUpdate : submitNew;
  const validate = () => 
    api.put(`/leases/${lease.id}`, 
      {...lease, validated: (new Date()).toISOString()})
      .then(setLease)
  const disabled = isLogistics && lease.validated

  return (
    <FormPage title={title}>
      <Card title={formTitle}>
        <Formik 
          onSubmit={submit} 
          initialValues={lease} 
          enableReinitialize
        >
          <Box mt={4}>
            <Grid container component={Form} direction='column'>
              {isLogistics &&
              <FormControl variant="standard">
                <Field
                  label="Fournisseur"
                  component={TextField}
                  name='supplier_id'
                  type='text'
                  validate={required}
                  variant="standard"
                  select
                  disabled={disabled}
                >
                  <MenuItem disabled value=''>
                    Choisissez un fournisseur
                  </MenuItem>
                  {suppliers.map(({id, name}) => 
                  <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Field>
              </FormControl>
              }
              {isLeaser &&
              <FormControl variant="standard">
                <Field
                  label="Client"
                  component={TextField}
                  name='client_id'
                  type='text'
                  validate={required}
                  variant="standard"
                  select
                >
                  <MenuItem disabled value=''>Choisissez un client</MenuItem>
                  {clients.map(({id, name}) => 
                  <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Field>
              </FormControl>
              }
              <FormControl variant="standard">
                <Field
                  label="Modèle"
                  component={TextField}
                  name='model_id'
                  type='text'
                  validate={required}
                  variant="standard"
                  select
                  disabled={disabled}
                >
                  <MenuItem disabled value=''>Choisissez un modèle</MenuItem>
                  {models.map(model => 
                  <MenuItem key={model.id} value={model.id}>
                    {model.name}
                  </MenuItem> 
                  )}
                </Field>
              </FormControl>              
              <Field
                component={TextField}
                name='bac_count'
                label='Nombre de bacs'
                type='number'
                validate={required}
                variant="standard"
                disabled={disabled}
              />
              <Field
                component={TextField}
                name='note'
                label='Remarque'
                type='text'
                variant="standard"
                multiline
                inputProps={{maxLength: 160}}
              />
              {leaseId && isLeaser &&
              <DatePicker
                name='end'
                label='Préparé le'
                clearValue={null}
                fullWidth
              />}
              {leaseId && isLeaser && lease.validated &&
              <DatePicker
                name='validated'
                label='Validé'
                clearValue={undefined}
                fullWidth
                disabled
              />}
              {leaseId &&
              <Grid item>
                <Field
                  component={Checkbox}
                  type='checkbox'
                  name='deleted'
                />
                <label>Effacé</label>
              </Grid>
              }
              <SubmitButton {...{submitSucceeded}}/>
              {leaseId && isLeaser && !lease.validated &&
              <Button secondary fullWidth onClick={validate} >
                Valider la demande
              </Button>
              }
            </Grid>
          </Box>
        </Formik>
      </Card>
    </FormPage>);
};

const required = value => (value == null ? 'Champs requis' : undefined);

const uniqueById = list => 
      Object.values(list.reduce(
        (dict, l) => {dict[l.id] = l; return dict},
        {}))
