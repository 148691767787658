import React, { useEffect, useState } from 'react';
import { Paper, Box, Table, TableHead, TableBody, TableCell,  TableRow, 
} from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { filterPlaceByText } from '@clients/shared/src/place/utils';
import { Load } from '../_utils';
import { Page, IconButton } from '../main';
import { SearchBar, useSearch } from '../searchbar';


/** The list of all stock triggers. */
export default function StockTriggers() {
  const [triggers, setTriggers] = useState([]);
  const api = useApi();
  useEffect(() => {
    api.get()
      .then(triggers => triggers.sort(sortTriggers))
      .then(setTriggers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const model_ids = [...(new Set(triggers.map(t => t.model_id)))];
  const models = model_ids.map(id => ({
    value: id || 'is_none',
    label: triggers.find(t => t.model_id===id)?.model || 'Tous'
  }))
  const filters = [
    {type: 'text', name: 'text',
      filterFn : (text, {place: name}) => filterPlaceByText({name}, text)},
    {template: 'models', items: models},
    {name: 'active', type: 'switch', label: 'Active', initialValue: true, 
      filterFn: (isOn, trigger) => !isOn || !trigger.deleted},
    {type: 'switch', name: 'mail', label: 'Envoie mail', 
      filterFn: (isOn, trigger) => !isOn || trigger.do_email},
    {type: 'switch', name: 'addDue', label: 'Crée contrat',
      filterFn: (isOn, trigger) => !isOn || trigger.do_add_due},
  ];
  const [searchBarProps, filteredTriggers] = useSearch(filters, triggers);

  return (
    <Page
      title='Alertes sur les stocks'
      options={<IconButton type='add' to='/stock-triggers/create'/>}
    >
    <SearchBar {...searchBarProps}/>
    <Load isLoading={api.isLoading}>
      <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto' >
          <Table> 
            <TableHead> 
              <TableRow>
                <TableCell children="Client" />
                <TableCell children="Modèle" />
                <TableCell children="Stock min" />
                <TableCell children="Stock max" />
                <TableCell children="Détails" />
              </TableRow>
            </TableHead> 
            <TableBody>
            {filteredTriggers.map(({id, place, upper_limit, lower_limit, model,
              deleted}) => (
              <TableRow key={id}>
                <TableCell
                  sx={{textDecorationLine: deleted ? 'line-through' : 'none'}}
                  children={place}
                />
                <TableCell children={model || 'Tous'}/>
                <TableCell children={lower_limit}/>
                <TableCell children={upper_limit}/>
                <TableCell>
                  <IconButton type="settings" to={'/stock-triggers/'+id}/>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          {(filteredTriggers.length===0) && <h3>Aucune alerte.</h3>}
        </Box>
    </Load>
    </Page>
  );
};

const sortTriggers = (a, b) => 
  a.place.localeCompare(b.place, 'fr', {'sensitivity':'base'})
  || (a.model || '').localeCompare((b.model || ''), 'fr',{'sensitivity':'base'})
  || a.lower_limit - b.lower_limit
  || a.upper_limit - b.upper_limit;
