import React  from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Grid } from '@mui/material';
import { Clear, Cached } from '@mui/icons-material';
import { useUserIs } from '@clients/shared/src/user/services';
import { Sticker } from '../qr/QRSticker';


/** A bac sticker. */
export default function BacCard ({bacId, deleteEvent, closed, unsync}) {
  const isSuperadmin = useUserIs()('superadmin');
  const history = useHistory();
  const handleDelete = event => {
    event.stopPropagation();
    deleteEvent();
  };
  return (
    <Grid item>
      <Card raised 
        sx={sxCard}
        onClick={() => { isSuperadmin && history.push('/bacs/' + bacId) }}
        style={{cursor: isSuperadmin ? 'pointer' : 'auto'}}
      >
        <CardContent sx={sxContent}>
          <Sticker
            value={bacId} 
            page={{name: 'Bac', path: '/bacs/'}}
            unsync={unsync}
          />
          {deleteEvent && <Clear sx={sxDelete} onClick={handleDelete} />}
          {closed && <Cached sx={sxClosed}/>}
        </CardContent>
      </Card>
    </Grid>
  );
}

const sxCard = theme => ({
  maxWidth: theme.components.bacCard.defaultProps.maxWidth,
  height: theme.components.bacCard.defaultProps.height,
  margin: 'auto',
  position: 'relative',
})
const sxContent = {
  '&:hover': {
    backgroundColor: 'defaultProps.lightGrey',
  },
}
const sxDelete = {
  position: 'absolute!important',
  top: 10,
  right: 10,
  cursor: 'pointer',
}
const sxClosed = {
  position: 'absolute',
  bottom: 10,
  right: 10,
}
