import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useApi, isEmail } from '@clients/shared/src/utils';
import { FormPage, SubmitButton, Card } from '../main';

/** The form to ask to recover your password. */
export default function SendRecover () {
  const api = useApi();
  const [message, setMessage] = useState();
  const onSubmit = values => api.post(undefined, values)
    .then(() => setMessage('Email de récupération envoyé.'));
  return (
    <FormPage title='Mot de passe oublié'>
      <Card title='Récupérer votre mot de passe par email'>
        <Formik onSubmit={onSubmit} initialValues={{email:''}}>
          <Form>
            <Field
              component={TextField}
              name='email'
              validate={isEmail}
              label='Email'
              type='email'
              variant="standard"
              required
              fullWidth
            />
            <SubmitButton secondary>Valider</SubmitButton>
            {message}
          </Form>
        </Formik>
      </Card>
    </FormPage>);
};
