import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { H5 } from './Typography';

/** Defaut Card usecase. */
export default function MyCard ({ title, mt=0, children }) {
  return (
    <Box component={Card} minWidth={280} mt={mt}>
      <CardContent>
        {title && <H5 align='center'>{title}</H5>}
        {children}
      </CardContent>
    </Box>)
};
