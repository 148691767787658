import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Table, TableHead, TableBody, TableCell, TableRow, Box, Paper
  } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { Page, Card, IconButton, SubmitButton } from '../main';
import { PlaceAutocomplete } from '../place';
import { Load } from '../_utils';

/** The list of all user's workplaces. */
export default function Workplaces({ match }) {
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const [ user, setUser ] = useState({id: match.params.user_id});
  const {first, last, email, workplaces} = user;
  const api = useApi();
  const loadUser = () => {api.get(`/users/${user.id}`).then(setUser)};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadUser, [user.id]);
  const removeWorkplace = workplaceId =>
    api.delete(`${match.url}/${workplaceId}`)
      .then(loadUser);
  const submit = ({workplace_id}, {resetForm}) =>
    api.post(null, {workplace_id})
      .then(() => setSucceeded(true))
      .then(resetForm).then(loadUser);
  const userName = [first, last].filter(x => x).join(" ") || email;

  return (
    <Page title={userName + ' - Lieux secondaires'}>
      <Load isLoading={api.isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
          {workplaces?.length>0 ?
          <Table>
            <TableHead> 
              <TableRow>
                <TableCell>Lieu</TableCell>
                <TableCell>Adresse</TableCell>
                <TableCell>Ville</TableCell>
                <TableCell>CP</TableCell>
                <TableCell>Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workplaces
                .sort((a, b) => a.name.localeCompare(b.name, 'fr'))
                .map(({name, address, city, postal_code, id}) => (
                <TableRow key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{address || ''}</TableCell>
                  <TableCell>{city || ''}</TableCell>
                  <TableCell>{postal_code || ''}</TableCell>
                  <TableCell>
                    <IconButton
                      type='delete'
                      text='Supprimer'
                      onClick={() => removeWorkplace(id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          : <Box p={4}>Aucun lieu secondaire</Box>}
        </Box>
      </Load>
      <Box sx={{mt: 2, mx: 'auto', width: '100%', maxWidth: '400px'}}>
        <Card title='Ajouter un lieu'>
          <Formik onSubmit={submit} initialValues={{workplace_id: ''}}>
            <Box component={Form} mt={4}>
              <PlaceAutocomplete name='workplace_id' />
              <SubmitButton secondary fullWidth {...{submitSucceeded}}>
                Ajouter
              </SubmitButton>
            </Box>
          </Formik>
        </Card>
      </Box>
    </Page>
  );
};
