import { isToday, isThisYear, addDays } from 'date-fns';

const stringifyDate = (date, additionalOptions = {}) => {
  const options = { 
    day: 'numeric', 
    month: 'long',
    year: isThisYear(date) ? undefined : 'numeric',
    ...additionalOptions
  };
  return date.toLocaleDateString('fr-FR', options);
}

const stringifyTime = date => {
  const options = { hour: 'numeric', minute: 'numeric' };
  return date.toLocaleTimeString('fr-FR', options);
}

const stringifyDateTime = date => {
  const options = {
    day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'
  };
  if (!isThisYear(date)){
    options.year = 'numeric'
  }
  return date.toLocaleTimeString('fr-FR', options);
}

const prettyDate = date => 
  isToday(date) ? stringifyTime(date) : stringifyDate(date)

const stdDate = input => {
  const date = (() => {
    if (!input) {
      return new Date();
    } else if (typeof input === 'number') {
      return addDays(new Date(new Date().toDateString()), input);
    } else if (typeof input.getMonth === 'function') { //check if it's a date
      return input
    }})()
  return date.toISOString()
}


export {
  stringifyDate,
  stringifyTime,
  stringifyDateTime,
  prettyDate,
  stdDate,
};
