import { useState, useEffect, useRef } from 'react';
import { useApi } from '../utils';
import { useIsLoggedIn } from '../user/services';
import { useAddEvent } from '../event/services';
import { analyzeScan } from '../scan';


/** A component which listen to scan events. */
export default function useKeyboardListener (redirection, destinationId,
  usePlaceRefs=false) {
    const [placeRefs, setPlaceRefs] = useState([]);
    const api = useApi();
    const isLoggedIn = useIsLoggedIn();
    useEffect(() => {isLoggedIn && usePlaceRefs &&
      api.get('/placerefs').then(setPlaceRefs);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])
    const addEvent = useAddEvent();
    const textBuffer = useRef('');
    const addChar = char => textBuffer.current += char
    const parseText = () => {
      console.log('reading', textBuffer.current)
      const {pathname, bacId, error} = handleScan(textBuffer.current, placeRefs)
      if (error) {
        console.log(error);
      } else if (bacId && destinationId) {
        addEvent(bacId, {destination_id: destinationId})
      } else if (redirection) {
        redirection(pathname, bacId)
      }
      textBuffer.current = '';
    }
    return {addChar, parseText}
}

const handleScan = (text, placeRefs) => {
  const valideRef = placeRefs.find(placeRef => placeRef.ref === text);
  const output = analyzeScan(text);
  if (output.error && valideRef) {
    return { pathname: '/places/' + valideRef.target_id };
  }
  return output;
}
