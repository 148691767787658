import React, { useState } from 'react'
import { FormHelperText, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { useApi } from '@clients/shared/src/utils';
import { Button, Card, Link } from '../main';
import PlaceAutocomplete from '../place/PlaceAutocomplete';

/** Form to edit an user's workplace. */
export default function Workplace ({initialWorkplace, user}) {
  const api = useApi();
  const [ message, setMessage ] = useState('');
  const [ place, setPlace ] = useState(initialWorkplace || {name:''});

  const onChange = (event, value) => {
    const {id: workplace_id } = value || {};
    if(workplace_id) {
      api.put(`/users/${user.id}/workplace`, {workplace_id})
      .then(() => setMessage('Lieu mis à jour.'))
      .then(() => setPlace(value));
    }
  };

  return (
    <Card mt={2} title='Lieux de travail'>
      <Formik onSubmit={()=>{}}
        initialValues={{place_id: place?.id || ''}}
        enableReinitialize
      >
        {() => (
        <Box component={Form} mt={4}>
          <PlaceAutocomplete 
            onChange={onChange}
            label='Lieu principal'
          />
        </Box>)}
      </Formik>
      <FormHelperText>{message}</FormHelperText>
      <Button secondary component={Link} fullWidth
        to={`/users/${user.id}/workplaces`}
      >
        LIEUX SECONDAIRES ({user.workplaces?.length || 0})
      </Button>
    </Card>);
};
