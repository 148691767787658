import React, { Fragment } from 'react';
import EventsPeriod from './EventsPeriod';

/** A component to display a list of events periods */
export default function EventsPeriodsList ({periods, placeId, text, 
    notReturned}) {
  const expandAll = (text && text.length >= 3) || 
    (notReturned && notReturned.length > 0);
  return (
    <Fragment>
      {periods.map((period, index) => 
        <EventsPeriod
          key={index} 
          placeId={placeId}
          events={period}
          expanded={expandAll || index === 0}
        />
      )}
    </Fragment>
  )
}
