import React from 'react';
import { Grid } from '@mui/material';
import { Avatar } from '../main';
import { ItemAutocomplete, filterAndSort, highlightMatch, } from '../_utils';

/** Autocomplete field fetching users' suggestions. */
export default function UserAutocomplete({showPhone, ...otherProps}) {
  return(
    <ItemAutocomplete 
      itemUrl='/users'
      filterOptions={(users, {inputValue}) =>{
        const validUsers = showPhone ? users.filter(user => user.phone) : users
        return filterAndSort(validUsers, inputValue, ['last', 'first','email'])
      }}
      renderOption={(optionProps, user, {inputValue : query}) => {
        optionProps.key = user.id;
        return (
          <UserItem {...{user, query, showPhone, ...optionProps}}/>
        )
      }}
      renderLabel={showPhone ? renderPhoneLabel : renderEmailLabel}
      {...otherProps}
    />);
};

/** Small representation of a user. */
const UserItem = ({user, query, showPhone, ...itemProps}) => {
  const { last, first, email, phone, id } = user;
  return (
    <Grid container spacing={4} mb={1} key={id} {...itemProps} >
      <Grid item >
        <Avatar user={user} />
      </Grid>
      <Grid item>
        {first && highlightMatch(first, query)}&nbsp;
        {last && highlightMatch(last, query)}<br/>
        {highlightMatch(email, query)}<br/>
        {showPhone && phone}
      </Grid>
    </Grid>
  );
};

const renderEmailLabel = user => user?.email || '';
const renderPhoneLabel = ({first='', last='', phone=''}={}) =>
  (first || last || phone) ? `${first} ${last} (${phone})` : '';
