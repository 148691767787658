import { SET_ERROR_MESSAGE } from "../actionTypes";

const initialState = {
  message: '',
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE: {
      const {message} = action.payload;
      return {
        ...state,
        message,
      };
    }
    default:
      return state;
  }
}