import React, { useState, Component, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Card, CardContent } from '@mui/material';
import { useCurrentUser } from '@clients/shared/src/user/services';
import FormPage from '../main/FormPage';


/** Class component to catch error. */
class ErrorBoundaryClassCpt extends Component {
  componentDidCatch (error, errorInfo) {
    this.props.componentDidCatch(error, errorInfo);;
  }
  render() {return (this.props.children)};
};

/** Catch and log error in rendering children. */
export default function ErrorBoundary ({ children }) {
  const [ hasError, setHasError ] = useState(false);
  const user = useCurrentUser();
  useEffect(() => {
    user.id
      ? Sentry.setUser({email: user.email, id: user.id})
      : Sentry.setUser(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);
  const componentDidCatch = (error, errorInfo) => {
    setHasError(true);
    console.log("Error boundary was called");
    console.log("Error:", error);
    console.log("Info:", errorInfo);
    Sentry.withScope(scope => {
      scope.setLevel('error');
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
  return (hasError ? 
    <FormPage title="Nous avons un problème">
      <Card>
        <CardContent>
          <p style={{'color':'#e25d26'}}>
            L'application a rencontré un problème. <br />
            Essayez de recharger la page. <br />
            Envoyez un mail à contact@pandobac.com si l'erreur persiste.
          </p>
        </CardContent>
      </Card>
    </FormPage> : 
    <ErrorBoundaryClassCpt
      componentDidCatch={componentDidCatch}
      children={children}
    />);
};
