import React from "react";
import { Checkbox } from "@mui/material";
import { useApi } from "@clients/shared/src/utils";
import { useUpdateTour } from "@clients/shared/src/tour/services";


/** A checkbox to handle adding and removing a place from a tour. */
export default function TourPlaceCheckbox ({placeId, tour, inputRef, setTour}) {
  const api = useApi();
  const updateTour = useUpdateTour();
  const url = `/tours/${tour.id}/places`;
  return (
    <Checkbox
      inputRef={inputRef}
      checked={tour.places.some(({id}) => id===placeId)}
      onClick={e => e.stopPropagation()}
      onChange={({target}) => {target.checked
        ? api.post(url, {'place_id' : placeId }).then(setTour || updateTour)
        : api.delete(url + '/' + placeId).then(setTour || updateTour)
      }}
    />
  );
}
