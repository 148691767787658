import React from 'react';
import { Paper, Box, MenuItem } from '@mui/material';
import { useUserIs } from '@clients/shared/src/user/services';
import Link from './Link';
import Page from './Page';
import { H5 } from './Typography';

/** A menu for management pages. */
export default function Management() {
  const userIs = useUserIs();
  return (
    <Page title="Gestion">
      <Box component={Paper} 
        width={{xs:0.9, md:0.5}} 
        my={{xs:4, md:16}} 
        mx='auto'
        p={2}
      >
        {links.filter(l => l.roles.some(role => userIs(role))).map(link =>
        <Link key={link.uri} to={link.uri}>
          <MenuItem xs={12}>
            <H5>{link.text}</H5>
          </MenuItem>
        </Link>)}
      </Box>
    </Page>);
};

const links = [
  {uri: '/users', text: 'Utilisateurs', roles: ['admin']},
  {uri: '/models', text: 'Modèles', roles: ['leaser']},
  {uri: '/leases', text: 'Contrats', roles: ['leaser', 'logistics']},
  {uri: '/stock-triggers', text: 'Alertes stocks', roles: ['logistics']},
  {uri: '/pickups', text: 'Collectes', roles: ['logistics']},
];
