import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { MenuItem, Typography } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { Card, SubmitButton } from '../main';
import PlaceAutocomplete from '../place/PlaceAutocomplete';

/** The form to see bac details and edit it. */
export default function BacUpdateForm ({bac, isEditing, refreshBac}) {
  const {shown_id, model_id, owner_id, caretaker_id} = bac;
  const [ models, setModels ] = useState([]);
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const api = useApi();
  useEffect(() => {
    api.get('/models').then(setModels)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const onSubmit = values => {
    api.put(`/bacs/${shown_id}`, values)
      .then(() => setSucceeded(true))
      .then(() => refreshBac())
  }
  return ( 
      <Card title='Détails du bac' mt={6}>
      <Formik onSubmit={onSubmit}
        initialValues={{model_id, owner_id, caretaker_id}}
        enableReinitialize
      >
        <Form>
          <Field
            component={TextField}
            name='model_id'
            label='Modèle'
            margin='dense'
            variant="standard"
            select
            fullWidth
            disabled={!isEditing}
          >
            {models.map(({id, name}) =>
            <MenuItem key={id} value={id}>
                <Typography variant='body1'>{name}</Typography>
            </MenuItem>
            )}
          </Field>
          <PlaceAutocomplete name='owner_id' label='Propriétaire'
            disableClearable
            disabled={!isEditing}
          />
          <PlaceAutocomplete name='caretaker_id' label='Locataire'
            disableClearable
            disabled={!isEditing}
          />
          {isEditing && <SubmitButton {...{submitSucceeded}}>
            Modifier
          </SubmitButton>}
        </Form>
      </Formik>
    </Card>
  );
};
