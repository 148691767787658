import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Send, People, ShoppingBasket, ViewWeek, Euro, LocalShipping, Book,
  Place as PlaceIcon, Timeline, CloudDownload, PersonAdd, FactCheck
} from '@mui/icons-material';
import { useDeletePlace, useWorkplace
} from '@clients/shared/src/place/services';
import { useApi, pluralize, stdDate, makeUrl } from '@clients/shared/src/utils';
import { useUserIs } from '@clients/shared/src/user/services';
import { useTours } from '@clients/shared/src/tour/services';
import { Link, Button, Card } from '../main';
import { ToursSelect } from '../tour';
import DeletePlaceDialog from './DeletePlaceDialog';

/** A menu to access extra settings of a place. */
export default function ExtraSettings({place, history}) {
  const userIs = useUserIs();
  const { id, name, employees, message_settings_cnt, bac_owned } = place;
  const tours = useTours();
  const tours_cnt = tours.filter(
    t => t.places.some(p => p.id === id)).length;
  const employeesLength = employees && employees.length;
  const [ isOpen, setIsOpen ] = useState(false);
  const deletePlace = useDeletePlace();
  const { id: workplace_id } = useWorkplace();
  const api = useApi();
  const { get } = api;
  const [emails, setEmails] = useState(new Set());
  useEffect(() => {
    const addEmails = list => setEmails(emails =>new Set([...emails, ...list]))
    if(id && userIs('admin')) {
      get(`/places/${id}/visible-users`)
        .then(resp => resp.map(vu => vu.email)).then(addEmails)
      get(`/messages-settings?place_id=${id}`)
        .then(resp => resp.map(ms => ms.recipient_email)).then(addEmails)
    }
  }, [get, id, userIs])

  const toggleDialog = () => { 
    setIsOpen(value => !value) 
  };

  const adminLinks = [
    {
      to: makeUrl('placerefs', {name, workplace_id}),
      text: 'Référence interne',
      icon: <ViewWeek/>,
    },
    {
      to: makeUrl('/tours', {place_id: id}),
      text: pluralize('tournée', tours_cnt) + ' desservant ce client',
      icon: <LocalShipping/>,
    },
    {
      to: makeUrl('/reports/places/'+id, {after: stdDate(-30)}),
      text: 'Synthèse client',
      icon: <Timeline/>
    },
    {
      to: makeUrl('/events/history', {after: stdDate(-7), text: name}),
      text: 'Historique client',
      icon: <Book/>
    },
    {
      to: makeUrl('/users', {place_id: id}),
      text: pluralize('compte', emails.size),
      icon: <People/>,
    },
    {
      to: `/places/${id}/visible-users/create`,
      text: 'Ajouter un compte',
      icon: <PersonAdd />,
    }
  ];

  const leaserLinks = [
    {
      to: makeUrl('bacs', {name}),
      text: pluralize('bac', bac_owned) + ' lui appartenant',
      icon: <ShoppingBasket/>,
    },
  ]

  const superadminLinks = [
    {
      to: makeUrl('/users', {place_id: id}),
      text: pluralize('utilisateur', employeesLength),
      icon: <People/>,
    },
    {
      to: makeUrl('/messages-settings', {origin_id: id, origin: name}),
      text: pluralize('message', message_settings_cnt) + 
            ' vers des lieux livrés',
      icon: <Send/>,
    },
    {
      to: makeUrl('/tours', {workplace_id: id}),
      text: 'Gérer les tournées',
      icon: <LocalShipping/>,
    },
    {
      to: 'billing',
      text: 'Facturation',
      icon: <Euro/>,
    },
    {
      to: makeUrl('/places/edit', {workplace_id: id}),
      text: 'Gérer les points de livraison',
      icon: <PlaceIcon/>
    },
    {
      to: makeUrl('/reports/bacs', {workplace_id:id}),
      text: 'Synthèse',
      icon: <Timeline/>
    },
    {
      to: makeUrl('/events/history', {workplace_id:id, after: stdDate(-7)}),
      text: 'Historique de son point de vue',
      icon: <Book/>
    },
    {
      to: makeUrl('/events/history', {workplace_id:id, after: stdDate(-7),
        include: 'next_event', showDeliveries: true}),
      text: 'Suivi livraisons de son point de vue',
      icon: <FactCheck/>
    },
    {
      to: makeUrl('/export/'+id),
      text: 'Exporter les données',
      icon: <CloudDownload/>
    },
  ];

  const onDeleteConfirm = () => {
    api.delete('/destinations/'+id)
      .then(toggleDialog)
      .then(() => deletePlace(id))
      .then(() => history.replace('/places'))
  };

  return id ? (
      <>
        <DeletePlaceDialog 
          place={place} 
          onConfirm={onDeleteConfirm} 
          onCancel={toggleDialog}
          open={isOpen}
        />
        {userIs('admin') &&
          <Card mt={4} title='Tournées'>
            <ToursSelect placeId={id} tours={tours} />
          </Card>
        }
        {userIs('admin') &&
          <RoleOptions title='Options Admin' links={adminLinks} >
            <Box style={{textAlign: 'center'}}>
              <Button secondary onClick={toggleDialog}>
                Retirer ce point de livraison
              </Button>
            </Box>
          </RoleOptions>
        }
        {userIs('leaser') &&
          <RoleOptions title='Option Loueur' links={leaserLinks} />
        }
        {userIs('superadmin') &&
          <RoleOptions title='Options Super Admin' links={superadminLinks} />
        }
      </>
    ) : null;
};

const RoleOptions = ({title, links, children}) => (
  <Card mt={4} title={title}>
    {links.map(link =>
      <ListItem key={link.text} button component={Link} to={link.to}>
        <ListItemIcon>{link.icon}</ListItemIcon>
        <ListItemText>{link.text}</ListItemText>
      </ListItem>
    )}
    {children}
  </Card>
)
