import React, { useRef } from 'react';
import { MenuItem, Typography } from '@mui/material';
import TourPlaceCheckbox from './TourPlaceCheckbox';


/** Component to show a tour-place checkbox with a clickable label. */
export default function TourItem ({placeId, id, name, places, setTour}) {
  const inputRef = useRef(null);
  return (
    <MenuItem
      sx={{justifyContent: 'space-between'}}
      onClick={() => inputRef.current?.click()}
    >
      <Typography variant='body1'>{name}</Typography>
      <TourPlaceCheckbox tour={{id, places}} {...{placeId, inputRef, setTour}}/>
    </MenuItem>
  )
}
