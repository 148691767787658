import React from 'react';
import { Grid, Typography, } from '@mui/material';
import BacCard from './BacCard';
import { stringifyDateTime } from '@clients/shared/src/utils';
import { Button, Sub2 } from '../main';


/** Display an event with all details. */
export default function Event ({event, isDelivery, actions, setBacAsLost}) {
  const { bac, bac_model, created, author, closed } = event;
  return (
    <Grid container 
      justifyContent='space-evenly'
      sx={{width: '100%', py: 4, px: 1}}
    >
      <BacCard bacId={bac} closed={closed}/>
      <Grid container direction='column' justifyContent='center'>
        <Typography>
          {stringifyDateTime(new Date(created))}
        </Typography>
        <Sub2 sx={{color: 'defaultProps.grey'}}>
          Scanné par {author}<br/>
          {bac_model || "Sans modèle"}
        </Sub2>
        {!closed &&
        <Sub2 color='error'>{isDelivery ? 'Non rendu' : 'En stock'}</Sub2>}
      </Grid>
        {actions && <Button secondary
          children='Bac perdu'
          disabled={closed}
          onClick={setBacAsLost}
          sx={{margin: 'auto 0'}}
        />}
    </Grid>
  );
}
