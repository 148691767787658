import React, { Fragment } from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { generate_code } from '@clients/shared/src/scan/validation';
import { StickerGrid, range } from './QRSticker';
import './qr.css';

/** Page to create QR code stickers. */
export default function BacQR ({prefix, startAt, size, col, repeat, 
  pageName, pagePath}) {

  const digits = 5;
  const ids = startAt ? range(size, startAt) : [];
  const stickers = [];
  
  for (var i=0; i<ids.length; i++) {
    for (var j=0; j<repeat; j++) {
      stickers.push({ code: generate_code(ids[i], digits, prefix) });
    }
  }
  //const text = stickers.reduce((txt, sticker) => txt+=sticker.code+'\n','');
  //console.log(text);
  
  document.title = 
    'Pando QR ' + pageName + ' ' + prefix 
    + ' ' + startAt +'-'+ (startAt + size -1);
  
  return(
    <Fragment>
      <Field
        component={TextField}
        className='no-print'
        name='prefix'
        label='Préfixe'
        parse={value=>value.toUpperCase()}
        variant="standard"
        required
        style={{width: '100px', marginRight: '35px'}}
      />
      <StickerGrid 
        col={col} 
        stickers={stickers}
        page={{name: pageName, path: pagePath}}
      />
    </Fragment>
  )
}
