import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from '../main';
import { stringifyDate } from '@clients/shared/src/utils';

/** Confirmation dialog box when editing a already used message settings. */
export default function EditWarning({created, onConfirm, onCancel, open}) {
  const date = stringifyDate(new Date(created));
  return(
    <Dialog open={open}>
      <DialogContent>
        Cet envoi périodique est utilisé depuis le {date}.
        Confirmez-vous sa modification ? <br/>
      </DialogContent>
      <DialogActions>
        <Button secondary mt={0} onClick={onConfirm}>
          Confirmer
        </Button>
        <Button mt={0} onClick={onCancel}>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>);
};
