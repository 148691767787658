import React, { useState, useEffect } from 'react';
import { Paper, Box } from '@mui/material';
import qs from 'query-string';
import { SearchBar, useSearch, useGetModels } from '../searchbar';
import { Page, Button } from '../main';

/** Page to download a .csv of all scans involving a place.*/
export default function DataExport({match, location}) {
  const placeId = match.params.place_id;
  const search = qs.parse(location.search, {parseNumbers: true});
  const [ isDisabled, setIsDisabled ] = useState(false);
  const models = useGetModels(search);
  const filters = [
    {template: 'workplace'},
    {template: 'after'},
    {template: 'before'},
    {template: 'models', items: models},
  ];
  const [ searchBarProps, ] = useSearch(filters, []);
  useEffect(() => {setIsDisabled(false)}, [location.search]);
  const onClick = () => setIsDisabled(isDisabled => !isDisabled);
  return (
    <Page title='Export des données'>
      <SearchBar {...searchBarProps}/>
      <Box component={Paper} mt={20} w={100} p={4} mx='auto'>      
        Vous pouvez sélectionner des dates pour l'export de données. 
        <Box my={2} style={{textAlign: 'center'}}>
          <Button disabled={isDisabled} onClick={onClick}>
            <a href={'/api/export/'+placeId+location.search} download
              style={{color: 'inherit', textDecoration: 'inherit'}} 
            >
              Télécharger
            </a>
          </Button>
        </Box>
      </Box>
    </Page>);
};
