import React from 'react';
import { Box, IconButton as MuiIconButton } from '@mui/material';
import { ContentCopy, Delete, Edit, Settings, FactCheck, Add
  } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Tooltip from './Tooltip';

/* Link button display an icon.*/
export default function IconButton({type, to, onClick, text, size='small'}) {
  return (
    <MuiIconButton
      size={size}
      sx={{color: 'text.secondary'}}
      to={to}
      component={to ? Link : Box}
      onClick={onClick}
    >
      <Tooltip text={text || types[type]?.text}>
        {types[type]?.icon}
      </Tooltip>
    </MuiIconButton>
  );
}

const types = {
  delete : {
    icon: <Delete/>,
    text: 'Retirer',
  },
  edit : {
    icon: <Edit/>,
    text: 'Editer',
  },
  settings: {
    icon: <Settings/>,
    text: 'Réglages',
  },
  duplicate : {
    icon: <ContentCopy/>,
    text: 'Dupliquer',
  },
  analyse : {
    icon: <FactCheck/>,
    text: 'Analyser',
  },
  add : {
    icon: <Add/>,
    text: 'Ajouter',
  },
}
