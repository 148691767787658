import React from 'react';
import { Grid, Divider, AccordionDetails } from '@mui/material';
import { eventServices } from '@clients/shared/src/event/event-services';
import { makeUrl } from '@clients/shared/src/utils';
import { Link } from '../main';
import EventsGroupCard from './EventsGroupCard';

/** Displays a list of events grouped by destination. */
export default function EventsGroupsList ({ events, placeId }) {
  const groups = eventServices.groupBy(events, ['destination_id', 'is_clean'])
  return (
    <Grid container component={AccordionDetails} direction='column'>
      {groups.filter(g => g[0].destination).map((group, index) => {
      const { destination_id } = group[0];
      const isDelivery = destination_id !== placeId;
      return (
        <Link 
          key={group[0].created}
          to={makeUrl('/events', {
            eventType: isDelivery ? 'deliveries' : 'returns',
            to_receive: isDelivery ? undefined : true,
            destination_id: destination_id,
            workplace_id: placeId,
            after: group.at(-1).created,
            before: group[0].created,
            is_clean: group[0].is_clean
          })}
        >
          <EventsGroupCard {...{isDelivery, group}} />
          {(index < events.length - 1) && <Divider/>}
        </Link>
      )})}
    </Grid>)
};
