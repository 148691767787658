import { useState, useEffect } from 'react';
import { makeUrl, useApi } from '../utils';
import { useTours, useUpdateTour } from './services';

/** State logic for Tours. */
export default function useToursState(workplace_id) {
  const api = useApi();
  const storeTours = useTours();
  const updateStoreTour = useUpdateTour();
  const [stateTours, setStateTours] = useState([]);
  const tours = workplace_id ? stateTours : storeTours;
  const updateTour = workplace_id ? ((tour) => setTours(tours => {
    const i = tours.findIndex(t => t.id === tour.id);
    tours[i] = tour;
    return tours.sort((a, b) => a.name.localeCompare(b.name));
  })) : updateStoreTour;

  useEffect(() => {
    workplace_id &&
      api.get(makeUrl('/tours', {workplace_id})).then(setStateTours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id]);

  return {tours, updateTour};
};
