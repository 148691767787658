import { configureStore } from '@reduxjs/toolkit'; //redux-starter-kit rename in toolkit
import { Sentry } from "../sentry";
import rootReducer from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

/** Redux store of reducers. */
const store = configureStore({
  reducer: { rootReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, //avoid warning in development
      serializableCheck: false,
    }),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
})

export default store
