import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../redux/actions';
import { getCurrentUser } from '../redux/selectors';
import { useApi, deviceStorage } from '../utils';

/** Save user hook when user successfully logged in. */
export function useSaveUser() {
  const dispatch = useDispatch();
  return (user) => {
    user.rememberMe && deviceStorage.setItem('user', JSON.stringify(user));
    dispatch(setCurrentUser(user));
    return user;
  }
};

/** Load user from local storage hook. */
export function useLoadUser() {
  const saveUser = useSaveUser();
  useEffect(()=>{
    deviceStorage.getItem('user').then(JSON.parse)
      .then(user => saveUser(user || {isLoading: false}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/** Get current user hook. */
export function useCurrentUser() {
  return useSelector(state => getCurrentUser(state.rootReducer));
};

/** User is logged in hook. */
export function useIsLoggedIn() {
  const { user_id } = useCurrentUser();
  return user_id ? true : false;
};

/** User is loading in hook. */
export function useIsLoading() {
  const { isLoading } = useCurrentUser();
  return isLoading || false;
};

/** Update the user data hook. */
export function useUpdateUser() {
  const api = useApi();
  const saveUser = useSaveUser();
  const currentUser = useCurrentUser();
  return () => api.get('/users/profile')
    .then(user => saveUser({...currentUser, ...user}));
}

/** Check user has a role hook. */
export function useUserIs() {
  const { roles } = useCurrentUser();
  return useCallback(role => roles && (roles.indexOf(role)>=0 || role==='user')
    , [roles])
};

/** Login hook. */
export function useLogin() {
  const api = useApi();
  const saveUser = useSaveUser();
  return (email, password, rememberMe) =>
    api.post('/users/login', { email, password, rememberMe })
      .then(user => {
        const currentUser = {...user, rememberMe};
        console.log('Logged in as', currentUser.email);
        return saveUser(currentUser);
      });
};

/** Logout current user hook. */
export function useLogout() {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const api = useApi();
  return () => {
    isLoggedIn && api.get('/users/logout');
    deviceStorage.removeItem('user')
      .then(() =>  dispatch(setCurrentUser({})));
  };
};

/** Load and refresh current user hook. */
export function useAutoupdateUser() {
  const isLoggedIn = useIsLoggedIn();
  const updateUser = useUpdateUser();
  const [isFresh, setIsFresh] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFresh(false);
    }, 12*60*60*1000); // 12 hours
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if(isLoggedIn && !isFresh){
      updateUser();
      setIsFresh(true);
    }
  }, [isLoggedIn, isFresh])
}
